// src/services/authServices.js
import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

export const PublishedTrip = () => {
  return axios.get(`${API_BASE_URL}/publishedTrips`);
};

export const getImages = (reference) => {
  return axios.get(
    `${API_BASE_URL}/maps/photo?reference=${reference}&maxWidth=700`
  );
};

export const getItineraryDetails = (tripId) => {
  return axios.get(`${API_BASE_URL}/trip/${tripId}/items`);
};

export const getFeaturedTravellersList = () => {
  return axios.get(`${API_BASE_URL}/users/featuredUsers`);
};

export const getfeaturedTravllerDetail = (travellerId) => {
  return axios.get(`${API_BASE_URL}/users/userDetailById/${travellerId}`);
};

export const getfeaturedTravllerPublishedTrips = (travellerId) => {
  return axios.get(
    `${API_BASE_URL}/publishedTripByUserId?userId=${travellerId}`
  );
};

//search itenasry
export const getSearchItinary = (searchQuery) => {
  return axios.get(`${API_BASE_URL}/new/searchTrip?search=${searchQuery}`);
};

//post api
export const getSearchDestinations = (payload) => {
  return axios.post(`${API_BASE_URL}/maps/autocomplete`, payload);
};

// search users
export const getSearchUsers = (searchQuery) => {
  return axios.get(`${API_BASE_URL}/users/search?search=${searchQuery}`);
};

// pupular in customerś

export const getPopularTrips = () => {
  return axios.get(`${API_BASE_URL}/new/itinerariesSortedByLikesDesc`); //?page=0&size=10
};

//weekend gateway

export const getWeekendGateways = () => {
  return axios.get(`${API_BASE_URL}/new/shortItinerariesSortedByLikesDesc`);
};

// long trips
export const getLongTrips = () => {
  return axios.get(`${API_BASE_URL}/new/longItinerariesSortedByLikesDesc`);
};

export const sortingItenairy = (payload) => {
  return axios.get(
    `${API_BASE_URL}/new/filteredTripsSearch?tripType=${payload?.selectedOption}&sort=${payload?.sortBy}` //?search=${search}
  );
};

//get place details
// {
//   LOCATION:""
// }
export const getPlaceDetails = (payload) => {
  return axios.post(`${API_BASE_URL}/maps/place-details`, payload);
};

// Get trending destinations
export const getTrendingDestinations = () => {
  return axios.get(`${API_BASE_URL}/ai/suggestionsInAutocomplete`);
};

export const getCoordinates = (payload) => {
  return axios.post(`${API_BASE_URL}/maps/place-details`, payload);
};
