import calenderIcon from "../../Assets/calenderIcon.svg";
import daysIcon from "../../Assets/daysIcon.svg";
import PopularTrips from "../../Assets/destinationDummyImg.jpg";
import userIcon from "../../Assets/userIcon.svg";
import "../components.css";

import { Skeleton } from "@mui/material";
import { formatDate } from "../../helper";
import { Likes } from "../atoms/Likes";

export const Card = ({
  image,
  data,
  style,
  height,
  onClick,
  loadingImages,
}) => {
  
  const getDaysorDate = () => {
    let startDate = {};
    let endDate = {};
    if (data.days === 0) {
      startDate = formatDate(data.startDate, "md");
      endDate = formatDate(data.endDate, "md");
      return `${startDate?.day}-${endDate?.day} ${endDate?.month} ${endDate?.year}`;
    }
    return `${data?.days} days`;
  };
  return (
    <div className="card" style={style} onClick={() => onClick(data.id)}>
      {loadingImages ? (
        <Skeleton variant="rectangular" width={250} height={200} />
      ) : (
        <div
          className="background-img"
          style={{
            backgroundImage: `url(${image?.config?.url || PopularTrips})`,
            height: height ? height : "202px",
            borderRadius: "12px",
            position: "relative",
            objectFit: "cover",
            // paddingBottom: `${aspectRatio}%`,
          }}
        >
          <Likes likesCount={data?.likes} />
        </div>
      )}

      <div style={{ padding: "0px 8px 8px 10px" }}>
        <p className="popular-trip-card-heading">{data.name}</p>
        <div className="card-description">
          <div className="card-description-items">
            <img src={calenderIcon} alt="Calendar" />
            <p className="popular-Tag-text">{getDaysorDate()}</p>
          </div>
          <div className="card-description-items">
            <img src={daysIcon} alt="Days" />
            <p className="popular-Tag-text">{data?.items} Places</p>
          </div>
        </div>
        {data?.associatedUsers?.[0]?.name && (
          <div className="card-description-items" style={{ marginTop: "8px" }}>
            <img src={userIcon} alt="User" />
            <p className="popular-Tag-text">
              {data?.associatedUsers?.[0]?.name}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
