import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { Provider } from "react-redux";
import { initGA, logPageView } from './analytics';


// Add AWIN Tracking Code Dynamically
const awinScript = document.createElement('script');
awinScript.src = 'https://tracking.awin.com/your-tracking-code.js';
awinScript.async = true;
document.head.appendChild(awinScript);


const measurementId = "G-BHE716ELVS"; // Replace with your GA4 measurement ID

initGA(measurementId);

const Root = () => {
  useEffect(() => {
    logPageView(); // Log the initial page view
  }, []);

  return <App />;
};


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  </Provider>
);
