export const PassIcon = (props) => {
  const { icon, ...rest } = props;
  return (
    <div
      style={{
        backgroundColor: "#F6F6F64D",
        boxShadow: "0px 5px 8px 0px #0000000F",
        borderRadius: "16px",
        padding: "4px",
        width: "38px",
        height: "38px",
        cursor: "pointer",
      }}
      {...rest}
    >
      <div
        style={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 5px 8px 0px #0000000F",
          borderRadius: "12px",
          padding: "5px",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={icon} alt="img" />
      </div>
    </div>
  );
};
