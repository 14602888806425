import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowIcon } from "./atoms/arrowIcon";
import { getFullName, getInitials } from "../helper";
import "./components.css";

const FeaturedTravellers = (props) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
    updateScrollButtons(); // Update button states after scrolling
  };

  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const handleScroll = () => {
    updateScrollButtons();
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      updateScrollButtons(); // Initial check on mount
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [props.featuredTravellersList]);

  const handleCardClick = (card) => {
    navigate(`/traveller-detail/${card.userId}`, { state: { card } });
  };

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        marginBottom: "100px",
        marginTop: "100px",
        background: props?.bgColor
          ? "linear-gradient(180deg, #F0F4F9 0%, #FAFAFA 100%)"
          : "",
      }}
    >
      <div className="main-popular-tags">
        <div>
          <div className="leftSidePadding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div>
                <p className="main-heading">Featured Travellers</p>
                <p className="description">
                  See the stories of travellers who are exploring the world in
                  style!
                </p>
              </div>
              <div className="scroll-Icon">
                <ArrowIcon
                  icon={<ArrowBackIcon />}
                  onClick={() => scroll(-100)}
                  disabled={!canScrollLeft}
                />
                <ArrowIcon
                  icon={<ArrowForwardIcon />}
                  onClick={() => scroll(100)}
                  disabled={!canScrollRight}
                />
              </div>
            </div>
          </div>
          <div
            className={`card-list-container ${
              canScrollLeft ? "remove-padding" : ""
            }`}
            style={{ marginTop: "55px" }}
          >
            <div className="card-list-travellers" ref={containerRef}>
              {props?.featuredTravellersList?.map((data) => (
                <Card
                  key={data.id}
                  data={data}
                  onClick={() => handleCardClick(data)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ data, onClick }) => {
  const imageSrc = `data:image/png;base64,${data?.userImageAsBase64}`;
  const avatar = getInitials(getFullName(data?.name));

  return (
    <div
      key={data?.userId}
      className="traveller-card"
      onClick={() => onClick(data?.userId)}
    >
      <div className="image-container-traveller">
        {data?.userImageAsBase64 === "" ? (
          <Avatar
            sx={{
              backgroundColor: "#20B08F",
              color: "white",
              width: "100%",
              height: "100%",
              borderRadius: "0px",
              fontSize: "60px",
              fontWeight: "500",
            }}
          >
            {avatar}
          </Avatar>
        ) : (
          <img
            src={imageSrc}
            alt="Featured Traveller"
            className="traveller-image"
          />
        )}

        <div className="overlay">
          <span className="overlay-text">View Profile</span>
        </div>
      </div>
      <div className="travellers-desc">
        <p className="travellers-desc-heading">{data?.username}</p>
        <p className="travellers-desc-2">
          {data?.tripsAssociatedCount} Itineraries
        </p>
      </div>
    </div>
  );
};

export default FeaturedTravellers;
