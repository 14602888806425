import { Stack, Typography } from "@mui/material";
import "../../Components/components.css";
import LocationIcon from "../../Assets/LocationIcon.svg";

const LocationUI = (props) => {
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Mulish",
  };
  return (
    <div className="travller-detail-location-tag">
      <img src={LocationIcon} alt="location icon" />
      <Typography
        style={{
          ...typographyStyle,
          fontSize: "12px",
          color: "#20B08F",
        }}
      >
        {props?.location}
      </Typography>
    </div>
  );
};

export default LocationUI;
