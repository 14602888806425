import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import { Link } from "react-router-dom";
import Apple from "../Assets/AppleIcon.png";
import Logo from "../Assets/Logo";
import Android from "../Assets/PlayStore.png";
import QRModal from "../Components/QRMOdal";

const Footer = () => {
  const quicksLinksArr = [
    {
      link: "/",
      text: "Explore",
    },
    {
      link: "/travel-guides",
      text: "Travel Guides",
    },

    // {
    //   link: "/Blogs",
    //   text: "Blogs",
    // },
  ];

  const openGmail = () => {
    window.open(
      "https://mail.google.com/mail/?view=cm&fs=1&to=admin@escapekar.com",
      "_blank"
    );
  };

  const PrivacyPolicyArr = [
    {
      link: "/terms-of-use",
      text: "Terms Of Use",
    },
    {
      link: "/privacy-policy/#privacy-policy-id",
      text: "Privacy Policy",
    },
    {
      link: "#",
      text: "Contact Us",
      action: openGmail,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className="Footer flex flex-col h-full lg:h-[298px] w-full justify-between bg-[#EEF0F3] border-t border-t-[#F1F1F1] z-10">
        <div className="flex flex-col lg:flex-row h-full items-center justify-center lg:justify-between  px-[80px] pt-10 lg:pt-0 ">
          <div className="hidden lg:flex flex-col items-start justify-start w-auto lg:w-[45%]">
            <a href="/" className="w-[70%] cursor-pointer">
              <Logo />
            </a>
          </div>
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between w-auto lg:w-[80%] gap-[40px]">
            <div className="flex flex-col items-center lg:items-start mt-[0px] lg:mt-0 gap-[10px]">
              <p className="lg:flex text-[18px] font-bold leading-[28px] font-Mulish mb-1 lg:mb-0">
                Quick Links
              </p>
              {quicksLinksArr.map((quickLink, index) => (
                <Link
                  to={quickLink.link}
                  className=" text-[16px]  font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
                >
                  {quickLink.text}
                </Link>
              ))}
            </div>
            <div className="flex flex-col items-center lg:items-start mt-[0px] lg:mt-0 gap-[10px]">
              <p className="lg:flex text-[18px] font-bold leading-[28px] font-Mulish mb-1 lg:mb-0">
                Policy
              </p>
              {PrivacyPolicyArr.map((policy, index) => (
                <span
                  key={index}
                  onClick={policy.action ? policy.action : undefined}
                  className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0 cursor-pointer"
                >
                  {policy.action ? (
                    policy.text
                  ) : (
                    <Link to={policy.link}>{policy.text}</Link>
                  )}
                </span>
              ))}
            </div>
            <div className="flex flex-col items-center lg:items-start justify-end  lg:mt-0 mb-[28px] lg:pt-0 border-t lg:border-t-0 border-t-[#F1F1F1]">
              <p className="text-[18px] font-bold leading-[28px] font-Mulish mb-[16px] lg:mb-[24px]">
                Download App
              </p>
              <div className="flex flex-col items-center gap-[10px]">
                <div className="flex flex-row items-center gap-[10px]">
                  <a
                    alt="Play Store"
                    href="https://play.google.com/store/apps/details?id=com.escapekar.escapekar"
                    target="_blank"
                  >
                    <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                      <img alt="Google play" src={Android} className="my-2" />
                      <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                        <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                          GET IT ON
                        </p>
                        <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                          Google Play
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    alt="Play Store"
                    href="https://apps.apple.com/in/app/escapekar-ai-trip-planner/id6446503558"
                    target="_blank"
                  >
                    <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                      <img
                        alt="App Store"
                        src={Apple}
                        className="my-1"
                        width="20%"
                      />
                      <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                        <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                          DOWNLOAD ON THE
                        </p>
                        <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                          App Store
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
            <div className="flex sm:hidden flex-col items-center ">
              <a href="/" className="w-[60%] cursor-pointer">
                <Logo />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center h-full lg:h-[60px] border-t border-t-[#E1E6EE] px-[20px] md:px-[80px]">
          <p className=" text-[12px] md:text-xs text-center lg:text-left font-normal font-Mulish text-[#999999] mb-5 lg:mb-0 mt-5 lg:mt-0">
            © 2024 Escape Planner Pvt Ltd. All rights reserved.
          </p>
          <div className="socialLinks flex flex-row items-center justify-end mb-[24px] lg:mb-0 gap-[22px]">
            <a
              alt="Play Store"
              href="https://www.instagram.com/escapekar/"
              target="_blank"
            >
              <InstagramIcon
                alt="Instagram"
                width="18px"
                className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
              />
            </a>
            <a
              alt="Play Store"
              href="https://www.facebook.com/profile.php?id=100092510055231"
              target="_blank"
            >
              <FacebookIcon
                alt="Facebook"
                width="18px"
                className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
              />
            </a>
            <a
              alt="Play Store"
              href="https://www.linkedin.com/company/escapekar/"
              target="_blank"
            >
              <LinkedInIcon
                alt="LinkedIn"
                width="18px"
                className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
              />
            </a>
            {/* <Link to="">
              <TwitterIcon
                alt="Twitter"
                width="18px"
                className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
              />
            </Link> */}
          </div>
        </div>

        <QRModal open={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default Footer;
