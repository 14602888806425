import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "../../Assets/SearchIcon.svg";

const OptionsInput = (props) => {
  const handleSelect = (data) => {
    props?.handleOptionChange(data);
  };

  return (
    <div style={{ width: "100%", maxWidth: "840px" }}>
      <form noValidate autoComplete="off">
        <FormControl
          sx={{
            width: "100%",
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            position: "relative", // Ensure relative positioning for absolute positioning inside
          }}
        >
          <TextField
            type="search"
            placeholder={props?.placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="" />
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "white",
                borderRadius: "14px",
                width: "100%",
                minHeight: "40px",
                maxHeight: "50px",
                maxWidth: "840px",
                boxShadow: "0px 0px 24px 0px #00000014",

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
            {...props}
          />
          {props?.showSelect && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {/* Vertical Separator Line */}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  mx: 2,
                  height: "30px",
                  borderLeft: "2px solid #ddd",
                  position: "absolute",
                  right: "20%", // Fixed to the end of the TextField
                  top: "35%",
                  transform: "translateY(-50%)",
                }}
              />{" "}
              <Select
                value={props?.selectedOption}
                displayEmpty // Display empty option
                disableUnderline
                inputProps={{
                  style: {
                    border: "none", // Remove border
                  },
                }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  position: "absolute",
                  right: "10px", // Fixed to the end of the TextField
                  top: "38%",
                  transform: "translateY(-50%)",
                  border: "none",
                  padding: "0px",
                }}
                onChange={(e) => handleSelect(e.target.value)} // handle select change
              >
                {/* <MenuItem value="" disabled>
                  {props?.selectedOption
                    ? props?.selectedOption
                    : "Select Option"}
                </MenuItem> */}
                {props?.choice.map((data, index) => (
                  <MenuItem value={data} key={index}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </FormControl>
      </form>
      {props?.errorMessage && <Typography>{props?.errorMessage}</Typography>}
    </div>
  );
};

export default OptionsInput;
