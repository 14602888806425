import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PopularTrips from "../../Assets/destinationDummyImg.jpg";
import { Skeleton } from "@mui/material";
import { getStoredImages } from "../../helper";
import { ArrowIcon } from "../atoms/arrowIcon";
import "./destination.css";

const CardsWithScroll = (props) => {
  const containerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const navigate = useNavigate();

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const handleScroll = () => {
    setIsScrolled(containerRef.current.scrollLeft > 0);
    updateScrollButtons();
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      updateScrollButtons(); // Check scroll buttons on mount

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    updateScrollButtons(); // Update scroll buttons when cards are loaded
  }, [props.cards]);

  const handleCardClick = (card) => {
    navigate(`/destination-detail/${card?.name}/${card?.place_id}`, {
      state: { card },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        cursor: "pointer",
        margin: "50px 0px",
      }}
    >
      <div className="main-popular-tags">
        <div>
          <div className="leftSidePadding">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="main-heading">{props?.heading}</p>
                <p className="description">{props?.description}</p>
              </div>
              <div className="scroll-Icon">
                <ArrowIcon
                  icon={<ArrowBackIcon />}
                  onClick={() => scroll(-100)}
                  disabled={!canScrollLeft}
                />
                <ArrowIcon
                  icon={<ArrowForwardIcon />}
                  onClick={() => scroll(100)}
                  disabled={!canScrollRight}
                />
              </div>
            </div>
          </div>
          <div
            className={`card-list-container ${
              isScrolled ? "remove-padding" : ""
            }`}
            style={{ marginTop: "50px" }}
          >
            <div className="card-list-travellers" ref={containerRef}>
              {props?.cards?.map((data, index) => {
                return (
                  <Card
                    key={data.id}
                    image={getStoredImages(data?.photos?.[0]?.photo_reference)}
                    data={data}
                    index={index}
                    onClick={() => handleCardClick(data)}
                    loadingDestination={props.loadingDestination}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* {props?.showbtn === true && (
          <div style={{ margin: "auto" }}>
            <Button1
              variant="outlined"
              title="View all"
              border={"1px solid #20B08F"}
              fontSize={"15px"}
              onClick={() => navigate(`/travel-guides`)}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CardsWithScroll;

const Card = ({
  image,
  data,

  onClick,

  loadingDestination,
}) => {
  return (
    <div className="destination-card" onClick={() => onClick(data.id)}>
      {loadingDestination ? (
        <Skeleton
          variant="rectangular"
          width={240}
          height={170}
          sx={{ borderRadius: "14px" }}
        />
      ) : (
        <img
          src={image?.config?.url || PopularTrips}
          alt="trips"
          style={{
            borderRadius: "14px",
            minHeight: "200px",
            width: "240px",
            objectFit: "cover",
          }}
        />
      )}

      {loadingDestination ? (
        <Skeleton
          variant="rectangular"
          width={100}
          height={118}
          sx={{ borderRadius: "14px" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "0px 0px 10px 10px",
            height: "100%",
            gap: "5px",
          }}
        >
          <p
            className="popular-trip-card-heading"
            style={{
              fontSize: "14px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
          >
            {data.name}
          </p>
          {data?.neighborhood || data?.locality ? (
            <div
              className="progress-bar-count"
              style={{
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {data?.neighborhood ?? ""}
              {data?.neighborhood && data?.locality && `, ${data.locality}`}
              {(!data?.neighborhood && data?.locality) ?? ""}
            </div>
          ) : (
            <></>
          )}

          <div className="">
            <p className="progress-bar-text">{data?.types}</p>
          </div>
        </div>
      )}
    </div>
  );
};
