import { Typography, debounce } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import backgroundImg from "../Assets/BackgroundVector.svg";
import "../Components/components.css";
import {
  clearLocalStorage,
  getImagefromBase64,
  getStoredImages,
} from "../helper";
import {
  getItineraryDetails,
  getPopularCustomerlist,
  getTrendingDestinations,
  getfeaturedTravllersList,
  searchDestinations,
  searchItenairy,
  searchUsers,
} from "../redux/actions/authActions";
import Header from "./Header";
import OptionsInput from "./atoms/OptionsInput";
import { RectangleCard } from "./template/RectangleCard";
import { logPageView } from "../analytics";
import { Helmet } from "react-helmet";

const SearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("Destination");

  const {
    searchUserList,
    searchDestinationList,
    searchItenairyList,
    popularTripsList,
    featuredTravellersList,
    trendingDestinationsList,
    searchLoading,
  } = useSelector((state) => state.auth);

  const choice = ["Destination", "Itinerary", "Users"];

  const getPlaceHolderText = () => {
    let placeHolder = "";
    if (selectedOption === "Destination") {
      placeHolder = "Search destination...";
    } else if (selectedOption === "Itinerary")
      placeHolder = "Search itinerary...";
    else if (selectedOption === "Users") placeHolder = "Search users...";
    return placeHolder;
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((searchInput) => {
      if (selectedOption === "Destination") {
        const payload = {
          location: searchInput,
        };

        dispatch(searchDestinations(payload));
      } else if (selectedOption === "Itinerary") {
        dispatch(searchItenairy(searchInput));
      } else if (selectedOption === "Users") {
        dispatch(searchUsers(searchInput));
      }
    }, 1000),
    [selectedOption]
  );

  const handleSearch = (searchInput) => {
    setSearchInput(searchInput);
    if (searchInput.length > 1 || !searchInput) {
      debouncedSearch(searchInput);
    }
  };

  useEffect(() => {
    clearLocalStorage();
    dispatch(getPopularCustomerlist());
    dispatch(getfeaturedTravllersList());
    dispatch(getTrendingDestinations());
  }, []);

  useEffect(() => {
    let obj = {
      name: "Search Page(All)",
      path: location.path,

    }
    logPageView(obj);
  }, [location])


  const listToShow = () => {
    const listToShowList = [];
    if (
      selectedOption === "Destination" &&
      searchDestinationList?.predictions?.length > 0
    ) {
      listToShowList.push(...searchDestinationList?.predictions);
    } else if (
      selectedOption === "Itinerary" &&
      searchItenairyList?.length > 0
    ) {
      listToShowList.push(...searchItenairyList);
    } else if (selectedOption == "Users" && searchUserList?.length > 0) {
      listToShowList.push(...searchUserList);
    }
    return listToShowList;
  };

  const handleCardClick = (card) => {
    navigate(`/destination-detail/${card.structured_formatting.main_text}/${card?.place_id}`, {
      state: { card },
    });
  };

  const handleCardClickFeaturedTravellers = (card) => {
    navigate(`/traveller-detail/${card.userId}`, { state: { card } });
  };

  const handleCardClickItenairy = (card) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
    dispatch(getItineraryDetails(card.id));
  };
  return (
    <>
      <Helmet>
        <title>Escapekar: Search Your Perfect Travel Itinerary</title>
        <meta name="title" content="Escapekar: Search Your Perfect Travel Itinerary" />
        <meta
          name="description"
          content="Search for the ideal travel itinerary, explore various destinations, and find popular travel options on Escapekar. Discover user-generated recommendations and top-rated travel plans to make your journey unforgettable."
        />
        <meta
          name="keywords"
          content="travel itinerary search, destination search, popular travel options, user-generated travel recommendations, top-rated travel plans, trip planning, search travel destinations"
        />

        <meta name="url" content="https://escapekar.com/search" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/search" />
        <meta
          property="og:title"
          content="Search Your Perfect Travel Itinerary on Escapekar"
        />
        <meta
          property="og:description"
          content="Find the perfect travel itinerary, explore destinations, and see popular travel options with Escapekar's search functionality. Discover user recommendations and top travel plans."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SearchResultsPage",
            name: "Escapekar Search Results",
            description: "Search for travel itineraries, explore destinations, and find popular options on Escapekar. Discover user recommendations and top-rated travel plans.",
            url: "https://escapekar.com/search",

            // mainEntity: [
            //   {
            //     "@type": "SearchAction",
            //     target: "https://escapekar.com/search?q={search_term_string}",
            //     queryInput: "required name=search_term_string"
            //   }
            // ],
            hasPart: [
              {
                "@type": "ItemList",
                name: "Itineraries",
                description: "Browse through various travel itineraries based on your search."
              },
              {
                "@type": "ItemList",
                name: "Destinations",
                description: "Explore destinations based on your search criteria."
              },
              {
                "@type": "ItemList",
                name: "User Recommendations",
                description: "Discover travel recommendations and plans shared by other users."
              },
              {
                "@type": "ItemList",
                name: "Popular Travel Options",
                description: "Find popular and top-rated travel plans and destinations."
              }
            ]
          })}
        </script>
      </Helmet>
      <Header />
      <div
        className="background-img"
        style={{
          backgroundImage: `url(${backgroundImg}) `,
          minHeight: "100vh",
          marginTop: "100px",
          padding: "20px",
        }}
      >
        <OptionsInput
          placeholder={getPlaceHolderText()}
          style={{ marginBottom: "20px", width: "100%" }} // Add margin bottom for spacing
          showSelect={true}
          choice={choice}
          value={searchInput}
          onChange={(e) => handleSearch(e.target.value)}
          selectedOption={selectedOption}
          handleOptionChange={(value) => {
            setSelectedOption(value);
            setSearchInput("");
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {searchLoading ? (
            <CircularProgress
              variant="indeterminate"
              sx={{
                color: "#20B08F",
              }}
              size={40}
              thickness={4}
            // value={100}
            />
          ) : (
            <>
              {searchInput?.length > 2 ? (
                <>
                  {listToShow()?.length ? (
                    <>
                      {" "}
                      {listToShow()?.map((data, index) => (
                        <RectangleCard
                          data={data}
                          type={selectedOption}
                          image={
                            selectedOption === "Users"
                              ? getImagefromBase64(data.userImageAsBase64)
                              : selectedOption === "Destination"
                                ? getStoredImages(data.imageReference)
                                : getStoredImages(data.reference)
                          }
                          name={
                            selectedOption === "Destination"
                              ? data?.structured_formatting?.main_text
                              : data?.name
                          }
                          userName={data?.username ?? data?.username}
                          desc={
                            selectedOption === "Destination"
                              ? data?.structured_formatting?.secondary_text
                              : data?.desc || data.associatedUsers?.[0].name
                          }
                          days={data?.days ?? data?.days}
                          handleClick={() => {
                            selectedOption == "Users"
                              ? handleCardClickFeaturedTravellers(data)
                              : selectedOption === "Destination"
                                ? handleCardClick(data)
                                : handleCardClickItenairy(data);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <Typography>No Data Found</Typography>
                  )}
                </>
              ) : selectedOption === "Destination" ? (
                <>
                  {" "}
                  <div
                    className="main-heading"
                    style={{ fontSize: "24px", padding: "0px 20px" }}
                  >
                    Popular Destinations
                  </div>
                  {trendingDestinationsList?.map((data, index) => (
                    <RectangleCard
                      image={getStoredImages(data.imageReference)}
                      name={data?.structured_formatting?.main_text}
                      desc={data?.structured_formatting?.secondary_text}
                      handleClick={() => handleCardClick(data)}
                      type={selectedOption}
                    />
                  ))}
                </>
              ) : selectedOption === "Itinerary" ? (
                <>
                  {" "}
                  <div
                    className="main-heading"
                    style={{ fontSize: "24px", padding: "0px 20px" }}
                  >
                    Popular Itinerary
                  </div>
                  {popularTripsList?.map((data, index) => (
                    <RectangleCard
                      image={getStoredImages(data.reference)}
                      name={data?.name}
                      desc={data?.associatedUsers[0]?.name} // need to pass data with user icon
                      handleClick={() => handleCardClickItenairy(data)}
                      type={selectedOption}
                    // days={data?.days} need to work on ui
                    />
                  ))}
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className="main-heading"
                    style={{ fontSize: "24px", padding: "0px 20px" }}
                  >
                    Featured Travellers
                  </div>
                  {featuredTravellersList?.map((data, index) => (
                    <RectangleCard
                      image={getImagefromBase64(data.userImageAsBase64)}
                      name={data?.name}
                      userName={data?.username ?? data?.username}
                      handleClick={() =>
                        handleCardClickFeaturedTravellers(data)
                      }
                      type={selectedOption}
                      data={data}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPage;
