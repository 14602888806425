import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/BackIcon.svg";
import calenderIcon from "../../Assets/calenderIcon.svg";
import daysIcon from "../../Assets/daysIcon.svg";
import PopularTrips from "../../Assets/destinationDummyImg.jpg";
import userIcon from "../../Assets/userIcon.svg";
import "../../Components/components.css";
import {
  clearLocalStorage,
  formatDate,
  getFullName,
  getInitials,
  getStoredImages,
} from "../../helper";
import {
  getFeaturedTravllerDetail,
  getItineraryDetails,
  getfeaturedTravllerPublishedTrips,
} from "../../redux/actions/authActions";
import Header from "../Header";
import DynamicTabs from "../atoms/DynamicTabs";
import { Likes } from "../atoms/Likes";
import { PassIcon } from "../atoms/PassIcon";
import AboutMeCard from "./AboutMeCard";
import FollowersUI from "./FollwersUI";
import LocationUI from "./LocationUI";
import { logPageView } from "../../analytics";

const TravellerDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { card } = location.state || {};
  const { featuredTravellerDetail, featuredTravllerPublishedTrip, loading } =
    useSelector((state) => state.auth);
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "600",
    color: "#333333",
    fontFamily: "Mulish",
  };

  const [tabValue, setTabValue] = useState("2");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    clearLocalStorage();
    if (card && card.userId) {
      dispatch(getFeaturedTravllerDetail(card.userId));
      dispatch(getfeaturedTravllerPublishedTrips(card.userId));
    }
  }, [card]);

  useEffect(() => {
    let obj = {
      name: "Traveller Detail",
      path: location.path,

    }
    logPageView(obj);
  }, [location])

  const imageSrc = `data:image/png;base64,${featuredTravellerDetail?.userImageAsBase64}`;

  const handleCardClick = (card) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
    dispatch(getItineraryDetails(card.id));
  };

  const avatar = getInitials(getFullName(featuredTravellerDetail?.name));

  const tabs = [
    {
      label: "Published Trips",
      value: "2",
      content: (
        <div className="traveller-detail-cards-list-detail-page">
          {featuredTravllerPublishedTrip?.trips?.map((data, index) => (
            <Card
              key={index}
              data={data}
              image={getStoredImages(data?.reference)}
              height="130px"
              onClick={() => handleCardClick(data)}
            />
          ))}
        </div>
      ),
    },
    {
      label: "About Me",
      value: "1",
      content: featuredTravellerDetail?.about ? (
        <AboutMeCard about={featuredTravellerDetail?.about} />
      ) : (
        <Typography
          fontSize={"14px"}
          fontFamily={"Mulish"}
          fontWeight={"700"}
          color={"#000000"}
          textAlign={"center"}
        >
          This traveller is still packing their bio. Check back soon!
        </Typography>
      ),
    },
  ];


  return (
    <div>
      <Header />
      <div className="travller-detail-main-desktop">
        {/* for desktop view */}
        <div className="travller-detail-img">
          <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

          <Typography style={typographyStyle}>Back</Typography>
        </div>

        {/* main ui started */}
        <div style={{ display: "flex", padding: "40px 4%", gap: "20px" }}>
          <div>
            <div>
              <div className="travller-detail-left-card">
                <div
                  style={{
                    boxShadow: "0px 3px 28px 3px #0000000A",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{ border: "4px solid white", borderRadius: "30px" }}
                  >
                    {featuredTravellerDetail?.userImageAsBase64 === "" ? (
                      <Avatar
                        sx={{
                          backgroundColor: "#20B08F",
                          color: "white",
                          borderRadius: "30px",
                          width: "180px",
                          height: "180px",
                          fontSize: "60px",
                          fontWeight: "500",
                        }}
                      >
                        {avatar}
                      </Avatar>
                    ) : loading ? (
                      <Skeleton
                        variant="rectangular"
                        width={180}
                        height={180}
                      />
                    ) : (
                      <img
                        src={imageSrc}
                        // alt="img"
                        style={{ borderRadius: "30px", objectFit: "cover", width: "180px", height: "180px" }}

                      />
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    style={{
                      ...typographyStyle,
                      fontSize: "14px",
                      color: "#000000",
                    }}
                  >
                    {featuredTravellerDetail?.name}
                  </Typography>

                  <Typography
                    style={{
                      ...typographyStyle,
                      fontSize: "14px",
                      color: "#999999",
                    }}
                  >
                    @{featuredTravellerDetail?.username}
                  </Typography>
                </div>
                {featuredTravellerDetail?.location ? (
                  <LocationUI location={featuredTravellerDetail?.location} />
                ) : (
                  <></>
                )}

                <FollowersUI
                  trips={featuredTravellerDetail?.tripsAssociatedCount}
                  followers={featuredTravellerDetail?.followerCount}
                />
              </div>
            </div>
          </div>
          <Stack gap="40px" width="100%">
            {featuredTravellerDetail?.about ? (
              <AboutMeCard
                about={featuredTravellerDetail?.about}
                addSeperator={true}
              />
            ) : (
              <></>
            )}
            <Typography
              style={{
                ...typographyStyle,
                fontSize: "18px",
                color: "#000000",
                marginLeft: "5px",
              }}
            >
              Trips
            </Typography>
            <div className="traveller-detail-cards-list-detail-page">
              {featuredTravllerPublishedTrip?.trips?.map((data, index) => (
                <Card
                  key={index}
                  data={data}
                  image={getStoredImages(data?.reference)}
                  width="244px"
                  onClick={() => handleCardClick(data)}
                />
              ))}
            </div>
          </Stack>
        </div>
      </div>

      {/* for mobile view started */}
      <div className="travller-detail-mobile-screen">
        <div className="travller-detail-mobile-header">
          <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

          {featuredTravellerDetail?.username ? (
            <Typography
              style={{
                ...typographyStyle,
                fontSize: "16px",
                color: "#000000",
                marginTop: "10px",
              }}
            >
              {featuredTravellerDetail?.username}
            </Typography>
          ) : (
            <></>
          )}
        </div>
        <div className="travller-details-all-details-mobile">
          <Stack gap="25px" width="100%" marginBottom={"20px"}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <div
                style={{
                  boxShadow: "0px 3px 28px 3px #0000000A",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{ border: "4px solid white", borderRadius: "30px" }}
                >
                  {featuredTravellerDetail?.userImageAsBase64 === "" ? (
                    <Avatar
                      sx={{
                        backgroundColor: "#20B08F",
                        color: "white",
                        borderRadius: "24px",
                        width: "80px",
                        height: "80px",
                        fontSize: "60px",
                        fontWeight: "500",
                      }}
                    >
                      {avatar}
                    </Avatar>
                  ) : loading ? (
                    <Skeleton variant="rectangular" width={80} height={80} />
                  ) : (
                    <img
                      src={imageSrc}
                      style={{
                        borderRadius: "24px",
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
              <FollowersUI
                trips={featuredTravellerDetail?.tripsAssociatedCount}
                followers={featuredTravellerDetail?.followerCount}
              />
            </Box>
            <Stack gap="10px">
              <div>
                <Typography
                  style={{
                    ...typographyStyle,
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  {featuredTravellerDetail?.name}
                </Typography>
                {featuredTravellerDetail?.username ? (
                  <Typography
                    style={{
                      ...typographyStyle,
                      fontSize: "14px",
                      color: "#999999",
                    }}
                  >
                    @{featuredTravellerDetail?.username}
                  </Typography>
                ) : (
                  <></>
                )}
              </div>
              {featuredTravellerDetail?.location ? (
                <Box sx={{ display: "flex", alignSelf: "baseline" }}>
                  {" "}
                  <LocationUI location={featuredTravellerDetail?.location} />
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          {/* Dynamic Tabs for mobile view */}
          <DynamicTabs tabs={tabs} value={tabValue} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default TravellerDetail;

const Card = ({
  image,
  data,
  style,
  height,
  onClick,
  width,
  loadingImages,
}) => {


  const startDateDay = formatDate(data?.startDate, "ncjkac")
  const getEndDate = () => {
    const date = formatDate(data?.endDate, "ncfkjsdcfk")
    return `${date?.day} ${date?.month} ${date?.year}`
  }
  return (
    <div className="travller-detail-trip-card" onClick={onClick}>
      {loadingImages ? (
        <Skeleton variant="rectangular" width={250} height={200} />
      ) : (
        <div
          className="background-img"
          style={{
            backgroundImage: `url(${image?.config?.url || PopularTrips})`,
            position: "relative",
            borderRadius: "12px",
            height: height ? height : "202px",
            width: width ? width : "",
            objectFit: "cover",
            minHeight: "60px",
          }}
        >
          <Likes likesCount={data?.likes} />
        </div>
      )}
      <div style={{ padding: "0px 8px 8px 10px" }}>
        <p className="popular-trip-card-heading">{data.name}</p>
        <div className="travller-detail-trip-card-description">
          <div className="card-description-items">
            <img src={calenderIcon} alt="Calendar" />
            <p className="popular-Tag-text">
              {`${data?.startDate ? `${startDateDay?.day} - ${getEndDate()}` : `${data?.days || 0} days`}`}</p>
          </div>
          <div className="card-description-items">
            <img src={daysIcon} alt="Days" />
            <p className="popular-Tag-text">{data.places?.length} Places</p>
          </div>
        </div>
        {data?.associatedUsers?.[0]?.name && (
          <div
            className=" md:flex hidden gap-[4px]"
            style={{ marginTop: "8px" }}
          >
            <img src={userIcon} alt="User" />
            <p className="popular-Tag-text">
              {" "}
              {data?.associatedUsers?.[0]?.name}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
