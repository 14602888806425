import * as React from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Hidden from "@mui/material/Hidden";
import Logo from "../Assets/Logo";
import Button1 from "./atoms/Button1";
import { Link } from "react-router-dom";
import MobileIcon from "../Assets/btnMobileIcon.svg";
import SearchIcon from "../Assets/SearchIcon.svg";
import { Stack } from "@mui/material";
import SideBarIcon from "../Assets/SideBarIcon.svg";
import { PassIcon } from "./atoms/PassIcon";
import { logEvent } from "../analytics";

const allLinks = [
  {
    name: "Explore",
    link: "/",
  },
  {
    name: "Travel Guides",
    link: "/travel-guides",
  },
  // {
  //   name: "About",
  //   link: "/about",
  // },
];

function Header(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation(); // Get the current location

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div
      className="headerPadding"
      style={{
        boxShadow: props?.boxShadow
          ? props?.boxShadow
          : "0px 3px 28px 3px #0000000a",
        marginBottom: "70px",
      }}
    >
      <AppBar position="static">
        <Toolbar disableGutters>
          {/* For Mobile */}
          <Hidden mdUp>
            <Box
              sx={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#FFFFFF",
                padding: "10px 15px 0px 15px",
                boxShadow: props?.boxShadow
                  ? props?.boxShadow
                  : "0px 3px 28px 3px #0000000a",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  mb: 1,
                  paddingLeft: "13px",
                  padding: "0px 0px 8px 8px",
                  borderBottom: "1px solid #EAEAEA",
                }}
              >
                <Stack>
                  <Typography
                    fontSize={"14px"}
                    fontFamily={"Mulish"}
                    fontWeight={"700"}
                    color={"#000000"}
                  >
                    Download our app today
                  </Typography>
                  <Typography
                    fontSize={"12px"}
                    fontFamily={"Mulish"}
                    fontWeight={"400"}
                    color={"#666666"}
                  >
                    Trip planning made easy
                  </Typography>
                </Stack>
                <Link
                  to="/get-help"
                  className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333]"

                >
                  <Button1
                    variant="contained"
                    title="Get App"
                    startIcon={MobileIcon}
                    border={"3px solid #CCF4EC"}
                    width="107px"
                  // onClick={() => logEvent('Button', 'Click')}

                  />
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <MenuIcon /> */}
                <PassIcon icon={SideBarIcon} onClick={handleOpenNavMenu} />

                <Link
                  to="/"
                  style={{
                    textAlign: "center",
                    width: "35%",
                    marginRight: "30px",
                  }}
                >
                  <Logo />
                </Link>

                <Link to="/search" style={{ marginBottom: "8px" }}>
                  <img src={SearchIcon} alt="Search" />
                </Link>
              </Box>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {allLinks.map((page) => (
                <Link
                  key={page.name}
                  to={page.link}
                  className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Mulish",
                      }}
                    >
                      {page.name}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Hidden>

          {/* For Desktop */}
          <Hidden mdDown>
            <Typography
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
            >
              <Link to="/">
                <Logo />
              </Link>
            </Typography>

            {/* Vertical Separator Line */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                mx: 2,
                height: "40px",
                borderLeft: "1px solid #ddd",
              }}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex", gap: "32px" },
              }}
            >
              {allLinks.map((page) => (
                <Link
                  key={page.name}
                  to={page.link}
                  className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      display: "block",
                      textTransform: "none",
                    }}
                  >
                    <Typography
                      color={
                        location.pathname === page.link ? "#20B08F" : "#000000"
                      }
                      fontWeight={location.pathname === page.link ? 700 : 400}
                      fontFamily="Mulish"
                    >
                      {page.name}
                    </Typography>
                  </Button>
                </Link>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "25px",
                flexGrow: 0,
                alignItems: "center",
              }}
            >
              <Link to="/search">
                <img src={SearchIcon} alt="" />
              </Link>
              <Link to="/get-help">
                <Button1
                  variant="contained"
                  title="Get App"
                  startIcon={MobileIcon}
                  border={"3px solid #CCF4EC"}
                />
              </Link>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
