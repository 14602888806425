// src/utils/loadMapKit.js

export function loadMapKitScript(callback) {
    const existingScript = document.getElementById('mapkit-jssdk');
  
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
      script.id = 'mapkit-jssdk';
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    } else {
      if (callback) callback();
    }
  }
  