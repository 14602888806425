import { Typography } from "@mui/material";
import "./destination.css";

const AboutCard = (props) => {
  return (
    <div className="paddingResponsiveDestination">
      <div
        className="mt-8 md:mt-0"
        style={{
          background: "#EAF0F4B2",
          borderRadius: "20px",
          flex: "1",
        }}
      >
        <Typography
          color="#000000"
          fontSize={"18px"}
          fontFamily={"Mulish"}
          fontWeight={"700"}
          padding={"16px 20px 12px 20px"}
        >
          {props?.heading ? props?.heading : "About Me"}
        </Typography>

        <Typography
          color="#999999"
          fontSize={"14px"}
          fontFamily={"Mulish"}
          fontWeight={"500"}
          padding="12px 20px 20px 20px"
          borderTop="1px solid #F1F1F1"
        >
          {props?.about}
        </Typography>
      </div>
    </div>
  );
};

export default AboutCard;
