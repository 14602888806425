export const ESCAPEKAR_ACTIONS = {
  // src/constants/actionTypes.js
  GET_PUBLISHED_TRIP_REQUESTED: "GET_PUBLISHED_TRIP_REQUESTED",
  GET_PUBLISHED_TRIP_SUCCESS: "GET_PUBLISHED_TRIP_SUCCESS",
  GET_PUBLISHED_TRIP_FAILED: "GET_PUBLISHED_TRIP_FAILED",
  // Add more action types as needed

  GET_IMAGES_REQUESTED: "GET_IMAGES_REQUESTED",
  GET_IMAGES_SUCCESS: "GET_IMAGES_SUCCESS",
  GET_IMAGES_FAILED: "GET_IMAGES_FAILED",

  GET_ITINERARY_DETAILS_REQUESTED: "GET_ITINERARY_DETAILS_REQUESTED",
  GET_ITINERARY_DETAILS_SUCCESS: "GET_ITINERARY_DETAILS_SUCCESS",
  GET_ITINERARY_DETAILS_FAILED: "GET_ITINERARY_DETAILS_FAILED",

  GET_FEATURED_TRAVELLERS_LIST_REQUESTED:
    "GET_FEATURED_TRAVELLERS_LIST_REQUESTED",
  GET_FEATURED_TRAVELLERS_LIST_SUCCESS: "GET_FEATURED_TRAVELLERS_LIST_SUCCESS",
  GET_FEATURED_TRAVELLERS_LIST_FAILED: "GET_FEATURED_TRAVELLERS_LIST_FAILED",

  GET_FEATURED_TRAVELLER_DETAIL_REQUESTED:
    "GET_FEATURED_TRAVELLER_DETAIL_REQUESTED",
  GET_FEATURED_TRAVELLER_DETAIL_SUCCESS:
    "GET_FEATURED_TRAVELLER_DETAIL_SUCCESS",
  GET_FEATURED_TRAVELLER_DETAIL_FAILED: "GET_FEATURED_TRAVELLER_DETAIL_FAILED",

  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED:
    "GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED",
  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_SUCCESS:
    "GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_SUCCESS",
  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_FAILED:
    "GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_FAILED",

  GET_POPULAR_CUSTOMER_LIST_REQUESTED: "GET_POPULAR_CUSTOMER_LIST_REQUESTED",
  GET_POPULAR_CUSTOMER_LIST_SUCCESS: "GET_POPULAR_CUSTOMER_LIST_SUCCESS",
  GET_POPULAR_CUSTOMER_LIST_FAILED: "GET_POPULAR_CUSTOMER_LIST_FAILED",

  GET_WEEKEND_GATEWAY_LIST_REQUESTED: "GET_WEEKEND_GATEWAY_LIST_REQUESTED",
  GET_WEEKEND_GATEWAY_LIST_SUCCESS: "GET_WEEKEND_GATEWAY_LIST_SUCCESS",
  GET_WEEKEND_GATEWAY_LIST_FAILED: "GET_WEEKEND_GATEWAY_LIST_FAILED",

  GET_LONG_TRIPS_LIST_REQUESTED: "GET_LONG_TRIPS_LIST_REQUESTED",
  GET_LONG_TRIPS_LIST_SUCCESS: "GET_LONG_TRIPS_LIST_SUCCESS",
  GET_LONG_TRIPS_LIST_FAILED: "GET_LONG_TRIPS_LIST_FAILED",

  SEARCH_ITENAIRY_REQUESTED: "SEARCH_ITENAIRY_REQUESTED",
  SEARCH_ITENAIRY_SUCCESS: "SEARCH_ITENAIRY_SUCCESS",
  SEARCH_ITENAIRY_FAILED: "SEARCH_ITENAIRY_FAILED",

  SEARCH_DESTINATION_REQUESTED: "SEARCH_DESTINATION_REQUESTED",
  SEARCH_DESTINATION_SUCCESS: "SEARCH_DESTINATION_SUCCESS",
  SEARCH_DESTINATION_FAILED: "SEARCH_DESTINATION_FAILED",

  SEARCH_USERS_REQUESTED: "SEARCH_USERS_REQUESTED",
  SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
  SEARCH_USERS_FAILED: "SEARCH_USERS_FAILED",

  SORTING_ITENAIRY_REQUESTED: "SORTING_ITENAIRY_REQUESTED",
  SORTING_ITENAIRY_SUCCESS: "SORTING_ITENAIRY_SUCCESS",
  SORTING_ITENAIRY_FAILED: "SORTING_ITENAIRY_FAILED",

  GET_PLACE_DETAILS_REQUESTED: "GET_PLACE_DETAILS_REQUESTED",
  GET_PLACE_DETAILS_SUCCESS: "GET_PLACE_DETAILS_SUCCESS",
  GET_PLACE_DETAILS_FAILED: "GET_PLACE_DETAILS_FAILED",

  GET_TRENDING_DESTINATIONS_SUCCESS: "GET_TRENDING_DESTINATIONS_SUCCESS",
  GET_TRENDING_DESTINATIONS_FAILED: "GET_TRENDING_DESTINATIONS_FAILED",
  GET_TRENDING_DESTINATIONS_REQUESTED: "GET_TRENDING_DESTINATIONS_REQUESTED",

  GET_COORDINATES_PLACES_REQUESTED: "GET_COORDINATES_PLACES_REQUESTED",
  GET_COORDINATES_PLACES_SUCCESS: "GET_COORDINATES_PLACES_SUCCESS",
  GET_COORDINATES_PLACES_FAILED: "GET_COORDINATES_PLACES_FAILED",

  ESCAPEKAR_FAIL_SAGA: "ESCAPEKAR_FAIL_SAGA",
  ESCAPEKAR_ERROR_SAGA: "ESCAPEKAR_ERROR_SAGA",
};
