import { Typography } from "@mui/material";
import DummyItenaryDetail from "../../Assets/destinationDummyImg.jpg";
import StarIcon from "../../Assets/StarIcon.svg";
import "../../Components/components.css";
import CarIcon from "../../Assets/CarIcon.svg";
import { useState } from "react";

const ItenaryDetailCard = ({ trip, image, onClick }) => {
  const [expanded, setExpanded] = useState(false);

  // Function to toggle expand/collapse
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Styles for the Typography component
  const truncatedStyle = {
    fontSize: "12px",
    color: "#999999",
    fontWeight: 500,
    fontFamily: "Mulish",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  };
  // CSS for full text
  const fullTextStyle = {
    fontSize: "12px",
    color: "#999999",
    fontWeight: 500,
    fontFamily: "Mulish",
  };

  // Determine if the text should show "View More" button based on its length
  const isLongText = trip?.description?.length > 300;
  return (
    <div onClick={onClick}>
      <div className="itenary-details-card mt-5">
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            src={image?.config?.url || DummyItenaryDetail}
            alt="place img"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "17px",
            }}
          />

          <div>
            <div className="text-sm md:text-base font-bold font-Mulish text-[#333333]">
              {trip.name ? trip.name : trip.startAddress}
            </div>
            {/* <Typography
              fontWeight={"700"}
              fontSize={"18px"}
              fontFamily={"Mulish"}
            >
              {trip.name ? trip.name : trip.startAddress}
            </Typography> */}
            <div style={{ display: "flex", gap: "10px" }}>
              <Typography
                fontSize={"12px"}
                color={"#999999"}
                fontWeight={"500"}
                fontFamily={"Mulish"}
              >
                {trip?.place_type ? trip?.place_type : "Attraction"}
              </Typography>{" "}
              {/* <Typography
                fontSize={"12px"}
                color={"#999999"}
                fontWeight={"500"}
                fontFamily={"Mulish"}
              >
                Historical
              </Typography>{" "} */}
              {/* <Typography
                fontSize={"12px"}
                color={"#20B08F"}
                fontWeight={"500"}
                fontFamily={"Mulish"}
              >
                Open
              </Typography>{" "} */}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          {trip?.rating && (
            <div style={{ display: "flex", gap: "3px" }}>
              <img
                src={StarIcon}
                alt="place img"
                width={"10px"}
                height={"10px"}
              />
              <Typography
                fontSize={"12px"}
                color={"#333333"}
                fontWeight={"700"}
                fontFamily={"Mulish"}
              >
                {`${trip?.rating} (${trip?.total_ratings})`}
              </Typography>
            </div>
          )}
          <Typography
            fontSize={"12px"}
            color={"#999999"}
            fontWeight={"700"}
            fontFamily={"Mulish"}
          >
            {trip?.cost_range_per_person_in_inr ? (
              `${trip?.cost_range_per_person_in_inr} /person`
            ) : (
              <></>
            )}
          </Typography>
        </div>

        {/* Description -card */}
        {trip?.description && (
          <div
            style={{
              borderTop: "1px solid #F1F1F1",
              padding: "7px 10px 7px 10px",
            }}
          >
            <Typography style={expanded ? fullTextStyle : truncatedStyle}>
              {trip?.description}
            </Typography>
            {isLongText && (
              <div onClick={toggleExpanded}>
                <Typography
                  fontSize={"10px"}
                  color={"#000000"}
                  fontWeight={"500"}
                  fontFamily={"Mulish"}
                  style={{ textDecoration: "underline" }}
                >
                  {expanded ? "View Less" : "View More"}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
      {(trip?.time || trip?.distance) && (
        <div
          style={{
            padding: "14px 10px",
            display: "flex",
            gap: "4px",
            justifyContent: "end",
          }}
        >
          {" "}
          <img src={CarIcon} alt="place img" />
          <Typography
            fontSize={"10px"}
            color={"#999999"}
            fontWeight={"500"}
            fontFamily={"Mulish"}
          >
            {trip?.time ? `${trip.time} min` : ""}
            {trip?.time && trip?.distance ? ", " : ""}
            {trip?.distance ? `${trip.distance} m` : ""}
          </Typography>
          {/* <Typography
          fontSize={"10px"}
          color={"#000000"}
          fontWeight={"500"}
          fontFamily={"Mulish"}
          style={{ textDecoration: "underline" }}
        >
          Directions
        </Typography> */}
        </div>
      )}
    </div>
  );
};

export default ItenaryDetailCard;
