import React from "react";
import { useNavigate } from "react-router-dom";
import { getStoredImages } from "../../helper";
import "./destination.css";

import ImageSlider from "./ImageSlider";

export const ViewAllImages = ({ placeDetailsPhotos, placeDetails }) => {
  const navigate = useNavigate();

  // Get valid images
  const images = placeDetailsPhotos
    ?.slice(0, 5) // Limit to 5 images
    .map((photo) => getStoredImages(photo?.photo_reference))
    .filter((image) => image?.config?.url);

  const handleViewAllImagesClick = () => {
    navigate(`/destinationDetail/gallery/${placeDetails?.name}`, {
      state: { placeDetailsPhotos, placeDetails },
    });
  };

  const getImagesStructure = (count) => {
    switch (count) {
      case 1:
        return (
          <div style={{ width: "100%" }}>
            <img
              src={images[0]?.config?.url}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                objectFit: "cover",
              }}
            />
          </div>
        );

      case 2:
        return (
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div className="view-all-images-1">
              <img
                src={images[0]?.config?.url}
                alt=""
                className="destination-image-wrapper"
              />
            </div>
            <div className="view-all-images-1">
              <img
                src={images[1]?.config?.url}
                alt=""
                className="destination-image-wrapper"
                style={{ borderRadius: "0px 20px 20px 0px" }}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div className="view-all-images-1">
              <img
                src={images[0]?.config?.url}
                alt=""
                className="destination-image-wrapper"
              />
            </div>
            <div className="view-all-images-In">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  src={images[1]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "48%",
                    borderRadius: "0px 20px 0px 0px",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={images[2]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                    borderRadius: "0px 0px 20px 0px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="view-all-images-In" style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <img
                src={images[0]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "48%",
                  borderRadius: "20px 0px 0px 0px",
                  objectFit: "cover",
                }}
              />
              <img
                src={images[1]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "49%",
                  borderRadius: "0px 0px 0px 20px",
                  objectFit: "cover",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <img
                src={images[2]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "48%",
                  borderRadius: "0px 20px 0px 0px",
                  objectFit: "cover",
                }}
              />
              <img
                src={images[3]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "49%",
                  borderRadius: "0px 0px 20px 0px",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <div className="view-all-images-1">
              <img
                src={images[0]?.config?.url}
                alt=""
                className="destination-image-wrapper"
              />
            </div>
            <div className="view-all-images-In">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  src={images[1]?.config?.url}
                  alt=""
                  style={{ width: "100%", height: "48%", objectFit: "cover" }}
                />
                <img
                  src={images[2]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                    objectFit: "cover"
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  src={images[3]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "48%",
                    borderRadius: "0px 20px 0px 0px",
                    objectFit: "cover"
                  }}
                />
                <img
                  src={images[4]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                    borderRadius: "0px 0px 20px 0px",
                    objectFit: "cover"
                  }}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="view-all-images-main">
      <div className="hidden lg:flex w-[100%] px-[50px] pb-[20px] ">
        {images?.length > 0 && getImagesStructure(images?.length)}
      </div>

      <>
        {images?.length ? (
          <div className="flex lg:hidden w-[100%] mb-[20px]">
            <ImageSlider
              images={images}
              onClick={() => handleViewAllImagesClick()}
            />
          </div>
        ) : null}
      </>

      <div
        className="view-all-images-button hidden md:flex"
        onClick={() => handleViewAllImagesClick()}
      >
        View all images
      </div>
    </div>
  );
};
