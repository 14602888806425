import { Avatar, Rating } from "@mui/material";
import "./destination.css";
import { getFullName, getInitials } from "../../helper";

export const RatingCard = ({ authour }) => {
  const avatar = getInitials(getFullName(authour?.author_name));
  return (
    <div className="reviews-card">
      <div className="reviews-card-header">
        {/* <img src={{ width: "40px", height: "40px", borderRaius: "60px" }}></img> */}
        <Avatar
          sx={{
            backgroundColor: "#20B08F",
            color: "white",
            width: "50px",
            height: "50px",
            borderRadius: "60px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {" "}
          {/* Render initials or default text */}
          {avatar}
        </Avatar>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div className="progress-bar-count">{authour?.author_name}</div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Rating
              name="read-only"
              value={authour?.rating}
              readOnly
              sx={{ fontSize: "20px" }}
            />
            <div className="progress-bar-text" style={{ fontSize: "10px" }}>
              {authour?.time}
            </div>
          </div>
        </div>
      </div>
      <div className="review-card-content">
        <p className="progress-bar-text" style={{ fontWeight: "500" }}>
          {authour?.text}
        </p>
      </div>
    </div>
  );
};
