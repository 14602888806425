import React from "react";
import { Select, MenuItem, InputAdornment } from "@mui/material";
const CustomSelect = ({
  labelId,
  id,
  value,
  options,
  onChange,
  placeholder,
  sx = {},
  adornmentText = "",
  defaultKey = "",
  handleChange,
  //   onChange,
  disabled = false,
  ...props
}) => {
  return (
    <Select
      labelId={labelId}
      id={id}
      value={value}
      //   onChange={onChange}
      displayEmpty
      sx={{
        width: { xs: "100%", sm: "240px" },
        border: "1px solid #333333",
        borderRadius: "12px",
        height: "46px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#333333",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#20b08f",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#20b08f",
          borderWidth: "2px",
        },
        ...sx, // Allow for overriding styles via props
      }}
      startAdornment={
        adornmentText && (
          <InputAdornment position="start">{`${adornmentText}: ${
            value ? "" : ""
          }`}</InputAdornment>
        )
      }
      {...props}
    >
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
      {options?.map((option, index) => {
        return (
          <MenuItem
            value={defaultKey ? option[defaultKey] : option}
            key={index}
            onClick={() => {
              handleChange(option);
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#e0efeb",
                color: "#333333",
                borderBottom: "1px solid #eaeaea",
              },
            }}
          >
            {defaultKey ? option[defaultKey] : option}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default CustomSelect;
