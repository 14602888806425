import React from "react";
import AppDetailsPic from "../../Assets/AppDetailsPic.svg";
import AppleIcon from "../../Assets/AppleIcon.png";
import Android from "../../Assets/PlayStore.png";

export const PlanYourNextTrip = (props) => {
  return (
    <div className="bothSidesPadding">
      <div className="Get-App-main" style={props?.style}>
        <div>
          <img src={AppDetailsPic} alt="App" />
        </div>
        <div className="content-Get-App">
          <div className="heading">
            Plan Your Next Trip With <span>Escapekar</span>
          </div>
          <div className="description">
            Your perfect travel buddy, packed with all the info you need. Scan
            the QR code or download from the link below!
          </div>
          <div className="flex flex-col items-center lg:items-start justify-end mt-[24px] lg:mt-0 mb-[28px] pt-[20px] lg:pt-0 border-t lg:border-t-0 border-t-[#F1F1F1]">
            <p className="text-[18px] font-bold leading-[28px] font-Mulish mb-[16px] lg:mb-[24px]">
              Download App
            </p>
            <div className="flex flex-row items-center gap-[10px]">
              <a
                alt="Play Store"
                href="https://play.google.com/store/apps/details?id=com.escapekar.escapekar"
                target="_blank"
              >
                <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                  <img alt="Google play" src={Android} className="my-2" />
                  <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                    <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                      GET IT ON
                    </p>
                    <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                      Google Play
                    </p>
                  </div>
                </div>
              </a>
              <a
                alt="Play Store"
                href="https://apps.apple.com/in/app/escapekar-ai-trip-planner/id6446503558"
                target="_blank"
              >
                <div className="flex flex-rowh-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                  <img
                    alt="App Store"
                    src={AppleIcon}
                    className="my-1"
                    width="20%"
                  />
                  <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                    <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                      DOWNLOAD ON THE
                    </p>
                    <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                      App Store
                    </p>
                  </div>
                </div>
              </a>
              {/* <img
                src={SmallQR}
                alt="QR"
                onClick={handleOpen}
                style={{ cursor: "pointer" }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
