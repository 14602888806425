import React, { useState } from "react";
import backgroundImg from "../../Assets/TravelGuidesbg.svg"; // Adjust the path as necessary
import OptionsInput from "../atoms/OptionsInput";
import { PlanYourNextTrip } from "../template/PlanYourNextTrip";
import Header from "../Header";
import { SearchPageContent } from "./SearchPagecontent";
import { PassIcon } from "../atoms/PassIcon";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/BackIcon.svg";

export const SearchPageDetail = () => {
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Mulish",
  };
  const navigate = useNavigate();
  return (
    <>
      {/* <Header /> */}
      <div className="travller-detail-mobile-header">
        <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

        <Typography
          style={{
            ...typographyStyle,
            fontSize: "16px",
            color: "#000000",
            marginTop: "10px",
          }}
        >
          Travel Detail
        </Typography>
      </div>
      <div style={{ position: "relative", width: "100%", height: "280px" }}>
        <div
          className="background-img"
          style={{
            backgroundImage: `url(${backgroundImg}) `,
            objectFit: "cover",
          }}
        ></div>
        <div className="optional-input-field">
          <OptionsInput
            placeholder="Find your dream itinerary..."
            showSelect={false}
            width="100px"
          />
        </div>
      </div>
      <SearchPageContent />

      <PlanYourNextTrip
        style={{
          flexDirection: "row-reverse",
          backgroundColor: "rgba(224, 239, 235, 0.3)",
          borderRadius: "10px",
        }}
      />
    </>
  );
};
