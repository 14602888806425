import * as actionTypes from "../constants/actionTypesConstants";
// reducers/authReducer.js
const initialState = {
  tripData: [],
  loading: false,
  istripDataSucess: false,
  error: null,
  tripDetails: "",
  searchItenairyList: [],
  searchDestinationList: [],
  searchUserList: [],
  longTripsList: [],
  popularTripsList: [],
  weekendGatewayList: [],
  featuredTravellersList: [],
  sortingItenairyList: [],
  trendingDestinationsList: [],
  searchLoading: false,
  loactionData: [],
  placeDetails: {},
};
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes?.ESCAPEKAR_ACTIONS?.ESCAPEKAR_ERROR_SAGA:
    case actionTypes?.ESCAPEKAR_ACTIONS?.ESCAPEKAR_FAIL_SAGA:
      return {
        ...state,
        ...payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PUBLISHED_TRIP_REQUESTED:
      return { ...state, loading: true };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PUBLISHED_TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
        tripData: payload,
        istripDataSucess: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PUBLISHED_TRIP_FAILED:
      return { ...state, loading: false, error: payload };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_IMAGES_SUCCESS:
      return {
        ...state,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_IMAGES_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_ITINERARY_DETAILS_FAILED:
      return {
        ...state,
        tripDetails: "",
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_ITINERARY_DETAILS_SUCCESS:
      return {
        ...state,
        tripDetails: payload,
        // istripDataSucess: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_ITINERARY_DETAILS_REQUESTED:
      return {
        ...state,
        tripDetails: ""
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_FEATURED_TRAVELLERS_LIST_FAILED:
      return {
        ...state,
        featuredTravellersList: [],
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_FEATURED_TRAVELLERS_LIST_SUCCESS:
      return {
        ...state,
        featuredTravellersList: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_FEATURED_TRAVELLERS_LIST_REQUESTED:
      return {
        loading: true,
        featuredTravellersList: [],
        error: null,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS
      ?.GET_FEATURED_TRAVELLER_DETAIL_REQUESTED:
      return {
        loading: true,
        featuredTravellerDetail: {},
        error: null,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_FEATURED_TRAVELLER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        featuredTravellerDetail: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_FEATURED_TRAVELLER_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        featuredTravellerDetail: {},
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS
      ?.GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED:
      return {
        ...state,
        featuredTravllerPublishedTrip: "",
        error: null,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS
      ?.GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_SUCCESS:
      return {
        ...state,
        featuredTravllerPublishedTrip: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS
      ?.GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_FAILED:
      return {
        ...state,
        featuredTravllerPublishedTrip: "",
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_POPULAR_CUSTOMER_LIST_REQUESTED:
      return {
        ...state,
        // popularTripsList: "",
        loading: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_POPULAR_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        popularTripsList: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_POPULAR_CUSTOMER_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_WEEKEND_GATEWAY_LIST_REQUESTED:
      return {
        ...state,
        // weekendGatewayList: "",
        loading: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_WEEKEND_GATEWAY_LIST_SUCCESS:
      return {
        ...state,
        weekendGatewayList: payload,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_WEEKEND_GATEWAY_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_LONG_TRIPS_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        // longTripsList: "",
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_LONG_TRIPS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        longTripsList: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_LONG_TRIPS_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_ITENAIRY_REQUESTED:
      return {
        ...state,
        searchItenairyList: [],
        searchLoading: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_ITENAIRY_SUCCESS:
      return {
        ...state,
        searchItenairyList: payload,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_ITENAIRY_FAILED:
      return {
        ...state,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_DESTINATION_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchDestinationList: [],
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_DESTINATION_SUCCESS:
      return {
        ...state,
        searchDestinationList: payload,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_DESTINATION_FAILED:
      return {
        ...state,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_USERS_REQUESTED:
      return {
        ...state,
        searchLoading: true,
        searchUserList: [],
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchUserList: payload,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SEARCH_USERS_FAILED:
      return {
        ...state,
        searchLoading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SORTING_ITENAIRY_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SORTING_ITENAIRY_SUCCESS:
      return {
        ...state,
        searchItenairyList: payload,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.SORTING_ITENAIRY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PLACE_DETAILS_REQUESTED:
      return {
        ...state,
        loadingDestination: true,
        placeDetails: {},
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDestination: false,
        placeDetails: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_PLACE_DETAILS_FAILED:
      return {
        ...state,
        loadingDestination: false,
        placeDetails: {},
        error: action.error,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_TRENDING_DESTINATIONS_REQUESTED:
      return {
        loading: true,
        trendingDestinationsList: [],
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_TRENDING_DESTINATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        trendingDestinationsList: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_TRENDING_DESTINATIONS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_COORDINATES_PLACES_REQUESTED:
      return {
        ...state,
        loactionData: [],
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_COORDINATES_PLACES_SUCCESS:
      return {
        ...state,
        loactionData: payload,
      };
    case actionTypes?.ESCAPEKAR_ACTIONS?.GET_COORDINATES_PLACES_FAILED:
      return {
        ...state,
        loactionData: [],
      };
    default:
      return state;
  }
};
export default authReducer;
