import "../components.css";

import { Avatar, Typography } from "@mui/material";
import SearchPageImg from "../../Assets/SearchPageImg.svg";
import { getFullName, getImagefromBase64, getInitials } from "../../helper";
import destinationDummyImg from "../../Assets/destinationDummyImg.jpg";

export const RectangleCard = ({
  image,
  name,
  desc,
  style,
  height,
  onClick,
  userName,
  data,
  type,
  handleClick,
}) => {
  const getImage = () => {
    if (type === "Destination" || type === "Itinerary") {
      if (Object.keys(image)?.length) return image?.config?.url;
      else return destinationDummyImg;
    } else if (type === "Users") {
      if (data?.userImageAsBase64) return image;
      else return;
    }
  };

  const avatar = getInitials(getFullName(data?.name));

  return (
    <div
      className="Horizontal-card"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      {type === "Users" && !data?.userImageAsBase64 ? (
        <Avatar
          sx={{
            backgroundColor: "#20B08F",
            color: "white",
            width: "50px",
            height: "50px",
            borderRadius: "16px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {" "}
          {/* Render initials or default text */}
          {avatar}
        </Avatar>
      ) : (
        <img
          src={getImage()}
          alt="Small Image"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "16px",
            objectFit: "cover",
          }}
        />
      )}
      <div>
        <Typography
          fontSize={"16px"}
          fontFamily={"Mulish"}
          fontWeight={"700"}
          color={"#000000"}
        >
          {name}
        </Typography>

        <Typography
          fontSize={"12px"}
          fontFamily={"Mulish"}
          fontWeight={"500"}
          color={"#999999"}
        >
          {userName ? userName : desc}
        </Typography>
      </div>
    </div>
  );
};
