import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import callIcon from "../../Assets/CallIcon.svg";
import ShareIcon from "../../Assets/ShareIcon.svg";
import VisitWebSiteicon from "../../Assets/VisitWebsiteIcon.svg";
import { destinationDetailTypes } from "../../Constants/Constant";
import "./destination.css";

export const SideClicks = ({ placeDetails, placeId }) => {

  const typeOfTags = placeDetails?.tags;
  const [showPopup, setShowPopup] = useState(false);

  const handleVisitWebsiteClick = () => {
    window.open(placeDetails?.website, "_blank");
  };

  const handleShareClick = () => {
    const url = window.location.href; 

    // Copy the current URL to the clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000); // Hide popup after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCallClick = (phoneNo) => {
    if (!isMobile) {
      return null; // or return something else for non-mobile users
    }
    window.location.href = `tel:${phoneNo}`;
  };

  return (
    <div className="links-responsive">
      {typeOfTags !== destinationDetailTypes[typeOfTags] && (
        <>
          {placeDetails?.formatted_phone_number && (
            <>
              <div className="hidden lg:flex">
                <Tooltip title={placeDetails?.formatted_phone_number} arrow={"up"}>
                  <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                    <img src={callIcon} alt="Call icon" />
                    <span className="links-text">Call</span>
                  </Box>
                </Tooltip>
              </div>
              <div className="flex lg:hidden">
                <Box
                  display={"flex"}
                  gap={"5px"}
                  alignItems={"center"}
                  onClick={() => handleCallClick(placeDetails?.formatted_phone_number)}
                >
                  <img src={callIcon} alt="Call icon" />
                  <span className="links-text">Call</span>
                </Box>
              </div>
            </>
          )}
          {placeDetails?.website && (
            <Box
              display={"flex"}
              gap={"5px"}
              alignItems={"center"}
              cursor="pointer"
              onClick={handleVisitWebsiteClick}
            >
              <img src={VisitWebSiteicon} alt="Visit Website icon" />
              <span className="links-text">Visit Website</span>
            </Box>
          )}
        </>
      )}
      <Box
        display={"flex"}
        gap={"5px"}
        alignItems={"center"}
        cursor="pointer"
        onClick={handleShareClick}
      >
        <img src={ShareIcon} alt="Share icon" />
        <span className="links-text">Share</span>
      </Box>
      {showPopup && <Box className="popup">Link copied!</Box>}
    </div>
  );
};
