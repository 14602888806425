import { Stack, Typography } from "@mui/material";
import "../../Components/components.css";

const AboutMeCard = (props) => {
  return (
    <div
      className={props?.className ?? ""}
      style={{
        backgroundColor: "#EAF0F4B2",
        borderRadius: "20px",
        flex: "1",
      }}
   
    >
      <Typography
        color="#000000"
        fontSize={"18px"}
        fontFamily={"Mulish"}
        fontWeight={"600"}
        padding={"16px 20px 12px 20px"}
      >
        {props?.heading ? props?.heading : "About Me"}
      </Typography>
      {/* {props?.addSeperator ? <div style={{border-top: 1px solid #F1F1F1}}></div> : null} */}

      <Typography
        color="#999999"
        fontSize={"14px"}
        fontFamily={"Mulish"}
        fontWeight={"500"}
        padding="12px 20px 20px 20px"
        borderTop="2px solid #F1F1F1"
      >
        {props?.about}
      </Typography>
    </div>
  );
};

export default AboutMeCard;
