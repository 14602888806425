import { Typography } from "@mui/material";
import Heart from "../../Assets/Heart.svg";
export const Likes = (props) => {
  return (
    <>
      {props?.likesCount > 0 ? (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            height: "26px",
            padding: "4px 8px 4px 8px",
            display: "flex",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "6px",
            position: props?.positionAbs === "no" ? "" : "absolute",
            bottom: "10px", // Adjust as needed
            right: "10px", // Adjust as needed
          }}
        >
          <img src={Heart} alt="heart icon" />

          <Typography
            style={{
              fontFamily: "Mulish",
              fontSize: "14px",
              color: "#666666",
              fontWeight: "700",
            }}
          >
            {props?.likesCount}
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
