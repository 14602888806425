import ReactGA from 'react-ga4';

export const initGA = (measurementId) => {
  ReactGA.initialize(measurementId);
};

export const logPageView = (props = "") => {

  const { path, name } = props
  // Send a pageview event with the page title
  ReactGA.send({
    hitType: 'pageview',
    page: path || window.location.pathname + window.location.search,
    title: name || document.name, // Set the page title
  });
};

export const logEvent = (eventCategory, eventAction) => {
  ReactGA.event({ category: eventCategory, action: eventAction });
};
