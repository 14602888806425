/* eslint-disable no-labels */
import * as actionsTypes from "../constants/actionTypesConstants";
const {
  GET_FEATURED_TRAVELLERS_LIST_REQUESTED,
  GET_FEATURED_TRAVELLER_DETAIL_REQUESTED,
  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED,
  GET_IMAGES_REQUESTED,
  GET_ITINERARY_DETAILS_REQUESTED,
  GET_LONG_TRIPS_LIST_REQUESTED,
  GET_PLACE_DETAILS_REQUESTED,
  GET_POPULAR_CUSTOMER_LIST_REQUESTED,
  GET_PUBLISHED_TRIP_REQUESTED,
  GET_TRENDING_DESTINATIONS_REQUESTED,
  GET_WEEKEND_GATEWAY_LIST_REQUESTED,
  SEARCH_DESTINATION_REQUESTED,
  SEARCH_ITENAIRY_REQUESTED,
  SEARCH_USERS_REQUESTED,
  SORTING_ITENAIRY_REQUESTED,
  GET_COORDINATES_PLACES_REQUESTED,
} = actionsTypes.ESCAPEKAR_ACTIONS;

// actions/authActions.js
export const getPublishedTrip = () => ({
  type: GET_PUBLISHED_TRIP_REQUESTED,
});

export const getImages = (reference) => ({
  type: GET_IMAGES_REQUESTED,
  reference,
});

export const getItineraryDetails = (tripId) => {
  return {
    type: GET_ITINERARY_DETAILS_REQUESTED,
    tripId,
  };
};

export const getfeaturedTravllersList = () => {
  return {
    type: GET_FEATURED_TRAVELLERS_LIST_REQUESTED,
  };
};

export const getFeaturedTravllerDetail = (featuredTravellerId) => {
  return {
    type: GET_FEATURED_TRAVELLER_DETAIL_REQUESTED,
    featuredTravellerId,
  };
};

export const getfeaturedTravllerPublishedTrips = (featuredTravellerId) => {
  return {
    type: GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED,
    featuredTravellerId,
  };
};

//POPULAR CUSTOMER LKIST
export const getPopularCustomerlist = () => {
  return {
    type: GET_POPULAR_CUSTOMER_LIST_REQUESTED,
  };
};

//weekend gateways
export const getWeekendGatewayList = () => {
  return {
    type: GET_WEEKEND_GATEWAY_LIST_REQUESTED,
  };
};

//long trips
export const getLongTripsList = () => {
  return {
    type: GET_LONG_TRIPS_LIST_REQUESTED,
  };
};

//search iṭēnar̥iy
export const searchItenairy = (searchQuery) => {
  return {
    type: SEARCH_ITENAIRY_REQUESTED,
    searchQuery,
  };
};

export const searchDestinations = (payload) => {
  return {
    type: SEARCH_DESTINATION_REQUESTED,
    payload,
  };
};

export const searchUsers = (searchQuery) => {
  return {
    type: SEARCH_USERS_REQUESTED,
    searchQuery,
  };
};

//sorting iṭēnar��iy
export const sortingItenairy = (payload) => {
  return {
    type: SORTING_ITENAIRY_REQUESTED,
    payload,
  };
};

// destinations details
export const getPlaceDetails = (payload) => {
  return {
    type: GET_PLACE_DETAILS_REQUESTED,
    payload,
  };
};

export const getTrendingDestinations = (payload) => {
  return {
    type: GET_TRENDING_DESTINATIONS_REQUESTED,
    payload,
  };
};

export const getCoordinates = (payload) => ({
  type: GET_COORDINATES_PLACES_REQUESTED,
  payload,
});
