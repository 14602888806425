import { IconButton } from "@mui/material";

export const ArrowIcon = (props) => {
  const { icon = <></>, onClick = () => {}, ...rest } = props;
  return (
    <IconButton onClick={onClick} {...rest}>
      <div
        style={{
          padding: "4px",
          gap: "10px",
          borderRadius: "16px",
          backgroundColor: "#EAEAEA",
          width: "38px",
          height: "38px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "5px",
            gap: "10px",
            borderRadius: "12px",
            // boxShadow: "0px 5px 8px 0px #0000000F, 0px 5px 8px 0px #0000000F",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>{icon}</>
        </div>
      </div>
    </IconButton>
  );
};
