export function flattenItems(tripArray) {
  const items = [];

  function extractItems(array) {
    array.forEach((item) => {
      items.push(item);
      if (item.items && item.items.length > 0) {
        extractItems(item.items);
      }
    });
  }

  extractItems(tripArray);
  return items;
}

// Return the first two initials
export const getInitials = (name) => {
  const nameParts = name.split(" ").filter(Boolean);
  const initials = nameParts
    .map((part) => part[0])
    .join("")
    .toUpperCase();
  return initials.slice(0, 1);
};

export const getFullName = (first = "", last = "") => {
  let fullName = `${first ? first : " "}` + " " + `${last ? last : " "}`;
  fullName = fullName.trim();
  return fullName;
};

export const getImagefromBase64 = (url) => {
  const imageSrc = `data:image/png;base64,${url}`;
  return imageSrc;
};

export const getStoredImages = (reference) => {
  const images = localStorage.getItem(`images_${reference}`);
  return images ? JSON.parse(images) : [];
};

export const clearLocalStorage = () => {
  localStorage.clear();
  return "cleared";
};

export const getRandomIndex = (arr, indexCounts) => {
  const randomIndexes = [];
  for (let i = 0; i < indexCounts; i++) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * arr.length);
    } while (randomIndexes.includes(randomIndex));
    randomIndexes.push(randomIndex);
  }
  return randomIndexes;
};

export function formatDate(dateString, place = "") {
  const date = new Date(dateString);

  // Short day of the week (first 3 letters)
  const dayOfWeek = date
    .toLocaleDateString("en-US", { weekday: "short" })
    .slice(0, 3);

  // Two-digit month and short month (first 3 letters)
  const digitMonth = date
    .toLocaleDateString("en-US", { month: "2-digit" });
  const month = date
    .toLocaleDateString("en-US", { month: "short" })
    .slice(0, 3);

  // Two-digit day and full year
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });
  const year = date.getFullYear();

  // Time with AM/PM
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
  const time = date.toLocaleTimeString("en-US", timeOptions);

  if (place) {
    return {
      dayOfWeek,
      day,
      month,
      year,
      digitMonth,
      time
    };
  } else {
    return `${day} ${month} ${year}, ${dayOfWeek} ${time}`;
  }
}
