import LodgingIcon from "../Assets/Loging.svg"
import CafeIcon from "../Assets/Cafe.svg"
import SleepICon from "../Assets/Sleep.svg"
import FlightIcon from "../Assets/Flight.svg"
import TrainIcon from "../Assets/Train.svg"

export const PlacesType = {
  PLACE: "Attraction",
  CAFE: "Cafe",
  SHOOPINGMALL: "Shopping Mall",
  ATTRACTION: "Attraction",
  TRAIN: "Train",
  FLIGHT: "Flight",
  HOTEL:"Hotel"
};

// tarvel guide dropdown types
export const sortByTypes = ["Recent", "Popular"];

// export const sortByTypes = {
//   recent: "Recent",
//   popular: "Popular",
// };

// travel guide types of trip dropdown
export const typesOfTrip = [
  { name: "dayTrips", displayName: "Day trips" },
  { name: "weekendGetaways", displayName: "Weekend Getaways" },
  { name: "shortTrips", displayName: "Short trips" },
  { name: "weekLongTrips", displayName: "WeekLong  trips" },
  { name: "longTrip", displayName: "Long trips" },
];

export const destinationDetailTypes = {
  // HOTEL = "Hotel",
  // CAFE = "Cafe",
  // SHOOPINGMALL = "Shopping Mall",
  // Attraction: "Attraction",
  City: "City",
  country: "country",
  State: "State",
};

export const tripsDays = {
  all: "All",
  0: "NYP",
};


export const PlacesTypeIcons = {
  ATTRACTION: LodgingIcon,
  HOTEL: SleepICon,
  CAFE: CafeIcon,
  SHOOPINGMALL: LodgingIcon,
  DINING: CafeIcon,
  FLIGHT: FlightIcon,
  TRAIN: TrainIcon
}
