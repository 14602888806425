import { styled } from "styled-components";
import "./destination.css";
import { Grid, LinearProgress, linearProgressClasses } from "@mui/material";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#eaae33" : "#308fe8",
  },
}));
export const Ratings = ({ placeDetails }) => {
  // Function to get average rating
  const getAvgRating = () => {
    const totalReviews = placeDetails.googleReviews.length;
    const sum = placeDetails.googleReviews.reduce(
      (acc, review) => acc + review.rating,
      0
    );
    return sum / totalReviews;
  };
  // Function to count ratings by each value
  const getRatingCount = (rating) => {
    return placeDetails.googleReviews.filter(
      (review) => review.rating === rating
    ).length;
  };
  // Function to calculate the percentage for progress bars
  const getProgressValue = (rating) => {
    const totalReviews = placeDetails.googleReviews.length;
    const count = getRatingCount(rating);
    return (count / totalReviews) * 100;
  };
  // Rating categories and labels
  const ratingCategories = [
    { label: "Excellent", rating: 5 },
    { label: "Very Good", rating: 4 },
    { label: "Average", rating: 3 },
    { label: "Poor", rating: 2 },
    { label: "Terrible", rating: 1 },
  ];
  return (
    <div className="ratings-main">
      <div>
        <div>
          <span className="ratings-by-users">{getAvgRating().toFixed(1)}</span>
          <span className="totalNumber5">/5</span>
        </div>
        <span className="total-reviews">
          {placeDetails?.googleReviews?.length ?? ""} Reviews
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {ratingCategories.map((category) => {
          const count = getRatingCount(category.rating);
          return (
            <div className="progress-bar" key={category.rating}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{ width: "250px" }}
              >
                <Grid item xs={3}>
                  <div className="progress-bar-text">{category.label}</div>
                </Grid>
                <Grid item xs={6}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={getProgressValue(category.rating)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <div className="progress-bar-count">{count}</div>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    </div>
  );
};
