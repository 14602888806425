import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import ModalQR from "../Assets/ModalQR.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
};

export default function BasicModal(props) {
  return (
    <div>
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <img src={ModalQR} alt="modal QR" />
        </Box>
      </Modal>
    </div>
  );
}
