import { all, call, put, takeLatest } from "redux-saga/effects";
import { clearLocalStorage, flattenItems } from "../../helper";
import * as actionTypes from "../constants/actionTypesConstants";

import {
  PublishedTrip,
  getFeaturedTravellersList,
  getImages,
  getItineraryDetails,
  getLongTrips,
  getPlaceDetails,
  getPopularTrips,
  getSearchDestinations,
  getSearchItinary,
  getSearchUsers,
  getTrendingDestinations,
  getWeekendGateways,
  getfeaturedTravllerDetail,
  getfeaturedTravllerPublishedTrips,
  sortingItenairy,
} from "../services/authServices";

const {
  ESCAPEKAR_ERROR_SAGA,
  ESCAPEKAR_FAIL_SAGA,
  GET_COORDINATES_PLACES_FAILED,
  GET_COORDINATES_PLACES_REQUESTED,
  GET_COORDINATES_PLACES_SUCCESS,
  GET_FEATURED_TRAVELLERS_LIST_REQUESTED,
  GET_FEATURED_TRAVELLERS_LIST_SUCCESS,
  GET_FEATURED_TRAVELLER_DETAIL_REQUESTED,
  GET_FEATURED_TRAVELLER_DETAIL_SUCCESS,
  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED,
  GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_SUCCESS,
  GET_IMAGES_FAILED,
  GET_IMAGES_REQUESTED,
  GET_IMAGES_SUCCESS,
  GET_ITINERARY_DETAILS_REQUESTED,
  GET_ITINERARY_DETAILS_SUCCESS,
  GET_LONG_TRIPS_LIST_REQUESTED,
  GET_LONG_TRIPS_LIST_SUCCESS,
  GET_PLACE_DETAILS_REQUESTED,
  GET_PLACE_DETAILS_SUCCESS,
  GET_POPULAR_CUSTOMER_LIST_REQUESTED,
  GET_POPULAR_CUSTOMER_LIST_SUCCESS,
  GET_PUBLISHED_TRIP_REQUESTED,
  GET_PUBLISHED_TRIP_SUCCESS,
  GET_TRENDING_DESTINATIONS_FAILED,
  GET_TRENDING_DESTINATIONS_REQUESTED,
  GET_TRENDING_DESTINATIONS_SUCCESS,
  GET_WEEKEND_GATEWAY_LIST_REQUESTED,
  GET_WEEKEND_GATEWAY_LIST_SUCCESS,
  SEARCH_DESTINATION_REQUESTED,
  SEARCH_DESTINATION_SUCCESS,
  SEARCH_ITENAIRY_REQUESTED,
  SEARCH_ITENAIRY_SUCCESS,
  SEARCH_USERS_REQUESTED,
  SEARCH_USERS_SUCCESS,
  SORTING_ITENAIRY_REQUESTED,
  SORTING_ITENAIRY_SUCCESS,
} = actionTypes.ESCAPEKAR_ACTIONS;

function* failSaga(failMsg, failPayload) {
  yield put({
    type: ESCAPEKAR_FAIL_SAGA,
    payload: failPayload || {},
  });
}

function* errorSaga(errorMsg, errorPayload) {
  yield put({
    type: ESCAPEKAR_ERROR_SAGA,
    payload: errorPayload || {},
  });
}

function* fetchSingleImages(item, keyName, storageKeyPrefix) {
  try {
    const reference = item[keyName];

    if (reference === null || reference === undefined) return;

    yield put({ type: GET_IMAGES_REQUESTED, reference, item });
    try {
      const response = yield call(getImages, reference);
      const images = response;

      localStorage.setItem(
        `${storageKeyPrefix}_${reference}`,
        JSON.stringify(images)
      );

      yield put({ type: GET_IMAGES_SUCCESS, payload: images, item });
    } catch (imageError) {
      yield put({ type: GET_IMAGES_FAILED, error: imageError.message, item });
    }
  } catch (error) {
    console.error("Error in fetchImages saga:", error);
    yield put({ type: GET_IMAGES_FAILED, error: error.message });
  }
}

// Fetch images and itinerary details for featured travellers
function* fetchImages(items, extractReference, storageKeyPrefix) {
  try {
    // yield all (clearLocalStorage())
    yield all(
      items?.map(function* (item) {


        const reference = extractReference(item);


        if (reference === null) return;
        yield put({ type: GET_IMAGES_REQUESTED, reference });
        try {
          const response = yield call(getImages, reference);
          const images = response;

          // Save images to local storage
          localStorage.setItem(
            `${storageKeyPrefix}_${reference}`,
            JSON.stringify(images)
          );

          yield put({ type: GET_IMAGES_SUCCESS, payload: images });
        } catch (imageError) {
          yield put({ type: GET_IMAGES_FAILED, error: imageError.message });
        }
      })
    );
  } catch (error) {
    console.error("Error in fetchImages saga:", error);
    yield put({ type: GET_IMAGES_FAILED, error: error.message });
  }
}

function* getPublishedTrip() {
  try {
    const response = yield call(PublishedTrip);
    if (response.status === 200) {
      const trips = response.data;
      yield put({ type: GET_PUBLISHED_TRIP_SUCCESS, payload: trips });

      // Use the generic fetchImages function
      yield call(fetchImages, trips.trips, (trip) => trip.reference, "images");
    } else {
      const failPayload = {
        loading: false,
        tripData: [],
        istripDataSucess: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    const errorPayload = {
      loading: false,
      tripData: [],
      istripDataSucess: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getItineraryDetail(action) {
  try {
    const response = yield call(getItineraryDetails, action?.tripId);
    if (response?.status === 200) {
      const tripData = response.data;

      yield put({ type: GET_ITINERARY_DETAILS_SUCCESS, payload: tripData });

      const allItems = flattenItems(tripData?.trip);
      yield call(
        fetchImages,
        allItems,
        (item) => item.photoReference,
        "images"
      );
    } else {
      const failPayload = {
        tripDetails: "",
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching published trips:", error);
    const errorPayload = {
      tripDetails: "",
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getFeaturedTravllerList() {
  try {
    const response = yield call(getFeaturedTravellersList);
    if (response.status === 200) {
      const featuredTravellersList = response.data.data;

      yield put({
        type: GET_FEATURED_TRAVELLERS_LIST_SUCCESS,
        payload: featuredTravellersList,
      });
    } else {
      const failPayload = {
        featuredTravellersList: [],
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    const errorPayload = {
      featuredTravellersList: [],
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getFeaturedTravellerdetailSaga(action) {
  try {
    const response = yield call(
      getfeaturedTravllerDetail,
      action?.featuredTravellerId
    );
    if (response.status === 200) {
      const featuredTravellerDetail = response.data;
      yield put({
        type: GET_FEATURED_TRAVELLER_DETAIL_SUCCESS,
        payload: featuredTravellerDetail,
      });
    } else {
      const failPayload = {
        featuredTravellerDetail: {},
        loading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching featured traveller detail:", error);
    const errorPayload = {
      featuredTravellerDetail: {},
      loading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getFeaturedTravellerPublishedTripList(action) {
  try {
    const response = yield call(
      getfeaturedTravllerPublishedTrips,
      action?.featuredTravellerId
    );
    if (response.status === 200) {
      const featuredTravellerPublishedTripList = response.data;
      yield put({
        type: GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_SUCCESS,
        payload: featuredTravellerPublishedTripList,
      });
      yield call(
        fetchImages,
        featuredTravellerPublishedTripList.trips,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        featuredTravllerPublishedTrip: "",
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching featured traveller detail:", error);
    const errorPayload = {
      featuredTravllerPublishedTrip: "",
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getPopularCustomerListSaga() {
  try {
    const response = yield call(getPopularTrips);
    if (response.status === 200) {
      const popularCustomerlist = response.data.data;
      yield put({
        type: GET_POPULAR_CUSTOMER_LIST_SUCCESS,
        payload: popularCustomerlist,
      });
      yield call(
        fetchImages,
        popularCustomerlist,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        popularTripsList: [],
        loading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching weekend gatway lisṭ", error);
    const errorPayload = {
      popularTripsList: [],
      loading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getWeekendGatewayListSaga() {
  try {
    const response = yield call(getWeekendGateways);
    if (response.status === 200) {
      const weekendgatewaylist = response.data.data;
      yield put({
        type: GET_WEEKEND_GATEWAY_LIST_SUCCESS,
        payload: weekendgatewaylist,
      });
      yield call(
        fetchImages,
        weekendgatewaylist,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        weekendGatewayList: [],
        loading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching Popular Customer Lisgt", error);
    const errorPayload = {
      weekendGatewayList: [],
      loading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getLongTripsListSaga() {
  try {
    const response = yield call(getLongTrips);
    if (response.status === 200) {
      const longTripslist = response.data.data;
      yield put({
        type: GET_LONG_TRIPS_LIST_SUCCESS,
        payload: longTripslist,
      });
      yield call(
        fetchImages,
        longTripslist,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        longTripsList: [],
        loading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching Popular Customer Lisgt", error);
    const errorPayload = {
      longTripsList: [],
      loading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getSearchItenairySaga(action) {
  try {
    const response = yield call(getSearchItinary, action?.searchQuery);
    if (response.status === 200) {
      const searchItenairyList = response.data.data;
      yield put({
        type: SEARCH_ITENAIRY_SUCCESS,
        payload: searchItenairyList,
      });
      yield call(
        fetchImages,
        searchItenairyList,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        searchItenairyList: [],
        searchLoading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    const errorPayload = {
      searchItenairyList: [],
      searchLoading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* searchDestinationSaga(action) {
  try {
    const response = yield call(getSearchDestinations, action?.payload);
    if (response.status === 200) {
      const searchDestinationList = response.data;
      yield put({
        type: SEARCH_DESTINATION_SUCCESS,
        payload: searchDestinationList,
      });
      yield call(
        fetchImages,
        searchDestinationList?.predictions,
        (trip) => trip.imageReference,
        "images"
      );
    } else {
      const failPayload = {
        // searchDestinationList: [],
        searchLoading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    const errorPayload = {
      // searchDestinationList: [],
      searchLoading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getSearchUsersSaga(action) {
  try {
    const response = yield call(getSearchUsers, action?.searchQuery);
    if (response.status === 200) {
      const searchUsersList = response.data.data;
      yield put({
        type: SEARCH_USERS_SUCCESS,
        payload: searchUsersList,
      });
      yield call(
        fetchImages,
        searchUsersList,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        searchUserList: [],
        searchLoading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    const errorPayload = {
      searchUserList: [],
      searchLoading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* sortingItenairySaga(action) {
  try {
    const response = yield call(sortingItenairy, action?.payload);
    if (response.status === 200) {
      const sortingItenairyList = response.data.data;
      yield put({
        type: SORTING_ITENAIRY_SUCCESS,
        payload: sortingItenairyList,
      });
      yield call(
        fetchImages,
        sortingItenairyList,
        (trip) => trip.reference,
        "images"
      );
    } else {
      const failPayload = {
        searchItenairyList: [],
        loading: false,
        error: response,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    const errorPayload = {
      searchItenairyList: [],
      loading: false,
      error: error,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getPlaceDetailsSaga(action) {
  try {
    const response = yield call(getPlaceDetails, action?.payload);

    if (response.status === 200) {
      const placeDetailsList = response?.data?.result;
      yield put({
        type: GET_PLACE_DETAILS_SUCCESS,
        payload: placeDetailsList,
      });

      yield all(clearLocalStorage());

      yield call(
        fetchImages,
        placeDetailsList?.photos,
        (trip) => trip.photo_reference,
        "images"
      );

      for (
        let start = 0;
        start < placeDetailsList?.attractions?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.attractions?.results[start]?.photos[0],
          "photo_reference",
          "images"
        );
      }

      for (
        let start = 0;
        start < placeDetailsList?.hiddenPlaces?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.hiddenPlaces?.results[start]?.photos[0],
          "photo_reference",
          "images"
        );
      }
    } else {
      const failPayload = {
        placeDetails: {},
        loading: false,
        error: response,
        loadingDestination: false,
      };
      yield call(failSaga, response, failPayload);
    }
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    const errorPayload = {
      placeDetails: {},
      loading: false,
      error: error,
      loadingDestination: false,
    };
    yield call(errorSaga, error, errorPayload);
  }
}

function* getTrendingDestinationsSaga(action) {
  try {
    const response = yield call(getTrendingDestinations, action?.payload);
    const trendingDestinationsList = response.data.predictions;
    yield put({
      type: GET_TRENDING_DESTINATIONS_SUCCESS,
      payload: trendingDestinationsList,
    });
    yield call(
      fetchImages,
      trendingDestinationsList,
      (trip) => trip.imageReference,
      "images"
    );
  } catch (error) {
    console.error("Error fetching searchItenairyList", error);
    yield put({
      type: GET_TRENDING_DESTINATIONS_FAILED,
      payload: error.message,
    });
  }
}

function* callApiByPlaceId(placeId) {
  try {
    let payload = {
      placeId: placeId,
    };
    let response = yield call(getPlaceDetails, payload);
    if (response?.status === 200) return response?.data?.result;
    else return response;
  } catch (error) {
    return error;
  }
}

function* getPlacesCoordinates(action) {
  try {
    const response = yield all(
      action?.payload?.map((placeId) => {
        return callApiByPlaceId(placeId);
      })
    );
    if (response?.length > 0) {
      yield put({
        type: GET_COORDINATES_PLACES_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_COORDINATES_PLACES_FAILED,
      payload: error.message,
    });
  }
}

function* authSaga() {
  yield takeLatest(GET_PUBLISHED_TRIP_REQUESTED, getPublishedTrip);
  yield takeLatest(GET_ITINERARY_DETAILS_REQUESTED, getItineraryDetail);
  yield takeLatest(
    GET_FEATURED_TRAVELLERS_LIST_REQUESTED,
    getFeaturedTravllerList
  );
  yield takeLatest(
    GET_FEATURED_TRAVELLER_DETAIL_REQUESTED,
    getFeaturedTravellerdetailSaga
  );
  yield takeLatest(
    GET_FEATURED_TRAVELLER_PUBLISHED_TRIP_REQUESTED,
    getFeaturedTravellerPublishedTripList
  );
  yield takeLatest(
    GET_POPULAR_CUSTOMER_LIST_REQUESTED,
    getPopularCustomerListSaga
  );

  yield takeLatest(
    GET_WEEKEND_GATEWAY_LIST_REQUESTED,
    getWeekendGatewayListSaga
  );
  yield takeLatest(GET_LONG_TRIPS_LIST_REQUESTED, getLongTripsListSaga);

  yield takeLatest(SEARCH_ITENAIRY_REQUESTED, getSearchItenairySaga);
  yield takeLatest(SEARCH_DESTINATION_REQUESTED, searchDestinationSaga);
  yield takeLatest(SEARCH_USERS_REQUESTED, getSearchUsersSaga);
  yield takeLatest(SORTING_ITENAIRY_REQUESTED, sortingItenairySaga);
  yield takeLatest(GET_PLACE_DETAILS_REQUESTED, getPlaceDetailsSaga);
  yield takeLatest(
    GET_TRENDING_DESTINATIONS_REQUESTED,
    getTrendingDestinationsSaga
  );
  yield takeLatest(GET_COORDINATES_PLACES_REQUESTED, getPlacesCoordinates);
}

export default authSaga;
