import Solo from "../Assets/Solo.svg";
import Couple from "../Assets/Couple.svg";
import Friends from "../Assets/Friends.svg";
import Business from "../Assets/Business.svg";
import Family from "../Assets/Family.svg";
import stars from "../Assets/stars.svg";

const VacationList = () => {
  const cardArr = [
    {
      img: Solo,
      text: "Solo",
    },
    {
      img: Couple,
      text: "Couple",
    },
    {
      img: Friends,
      text: "Friends",
    },
    {
      img: Family,
      text: "Family",
    },
    {
      img: Business,
      text: "Business",
    },
  ];

  return (
    <div className="bothSidesPadding">
      <div className="vacation-list-card">
        <div
          className="vacation-list-card-in"
          style={{
            backgroundImage: `url(${stars}),url(${stars}),url(${stars}),url(${stars}),url(${stars}),url(${stars})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition:
              "top left, top right, bottom left, bottom right, center center, bottom center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              backgroundImage: `url(${stars}),url(${stars})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: " bottom right,center left",
            }}
          >
            <div className="heading-1">VACATION FOR ALL TYPE OF</div>
            <div className="heading-2">Travellers</div>
          </div>
          <div className="vacation-list-cards">
            {cardArr.map((card, index) => (
              <div key={index} className="card-vacation">
                <img
                  src={card.img}
                  alt={card.text}
                  style={{ borderRadius: "16px 16px 0px 0px" }}
                />
                <div className="heading-1">{card.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationList;
