import * as React from "react";
import Modal from "@mui/material/Modal";
// import ModalClose from "@mui/joy/ModalClose";
// import Typography from "@mui/joy/Typography";

import { Box } from "@mui/material";
import { Fade } from "react-slideshow-image";
import CloseIcon from "@mui/icons-material/Close";
// import Backdrop from "@mui/joy/Backdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "rgba(0, 0, 0, 0.5)",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  height :"100%"
};

export default function PreviewModal({ open, handleClose, images }) {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <div
            className="slide-container"
            style={{
              height: "500px",
              width: "100%",
            }}
          >
            <Fade>
              {images?.map((fadeImage, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: "500px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "90%",
                        height: "100%",
                        objectFit: "cover", // or "contain" depending on your needs
                        cursor:"pointer"
                      }}
                      src={fadeImage?.config?.url}
                    />
                    {/* <h2>{fadeImage.caption}</h2> */}
                  </div>
                );
              })}
            </Fade>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
