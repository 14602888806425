import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import backgroundImage from "../Assets/TravelGuidesbg.svg"; // Adjust the path as necessary
import FeaturedTravellers from "../Components/FeaturedTravellers";
import Header from "../Components/Header";
import VacationList from "../Components/VacationList";
import CardsWithScroll from "../Components/atoms/CardsWithScroll";
import OptionsInput from "../Components/atoms/OptionsInput";
import { SearchPageContent } from "../Components/searchpageItenary/SearchPagecontent";
import { logPageView } from "../analytics";
import { clearLocalStorage } from "../helper";
import {
  getLongTripsList,
  getPopularCustomerlist,
  getWeekendGatewayList,
  getfeaturedTravllersList,
  searchItenairy,
} from "../redux/actions/authActions";

const TravelGuides = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [searchErrorInput, setSearchErrorInput] = useState("");

  const {
    featuredTravellersList,
    popularTripsList,
    weekendGatewayList,
    longTripsList,
    searchItenairyList,
    loading,
    loadingImages,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    clearLocalStorage();
    dispatch(getPopularCustomerlist());
    dispatch(getWeekendGatewayList());
    dispatch(getLongTripsList());
    dispatch(getfeaturedTravllersList());
  }, []);

  useEffect(() => {
    if (searchInput.length > 2) {
      dispatch(searchItenairy(searchInput));
      setSearchErrorInput("");
    } //else setSearchErrorInput("* Write Max 3 letters to search itenairy");
  }, [searchInput]);


  useEffect(() => {
    let obj = {
      name: "Travel Guides",
      path: location.path,

    }
    logPageView(obj);
  }, [location])


  return (
    <>
      <Helmet>
        <title>Escapekar: Your Ultimate Travel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Travel Guide" />
        <meta
          name="description"
          content="Plan your perfect trip with Escapekar's ultimate travel guide. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="travel guide, featured travels, hidden gems, popular destinations, travel itinerary, trip planner, personalized recommendations, search destinations, travel planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured travels, hidden gems, and popular destinations with Escapekar's travel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelGuide",
            name: "Escapekar Travel Guide",
            description: "Your ultimate resource for travel planning. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],

            hasPart: [
              {
                "@type": "TravelDestination",
                name: "Featured Travels",
                description: "Explore our featured travel destinations and experiences."
              },
              {
                "@type": "TravelDestination",
                name: "Hidden Gems",
                description: "Discover hidden gems and lesser-known destinations."
              },
              {
                "@type": "TravelDestination",
                name: "Popular Destinations",
                description: "Browse popular destinations that are favored by travelers."
              }
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            }
          })}
        </script>
      </Helmet>

      <Header />

      {/* <div
        className="travel-guides-mobile-header"
        style={{ marginTop: "125px" }}
      >
        <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

        <Typography
          style={{
            ...typographyStyle,
            fontSize: "16px",
            color: "#000000",
            marginTop: "10px",
          }}
        >
          Travel Detail
        </Typography>
      </div> */}

      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px", // Adjust the height as needed
          width: "100%", // Adjust the width as needed
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          objectFit: "cover",
        }}
      >
        <div className="flex flex-col gap-1 mt-12 md:mt-5 md:gap-[40px]">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
              textAlign: "center",
            }}
          >
            <div className="main-heading" style={{ color: "white" }}>
              {" "}
              From Dreams to Destinations
            </div>

            <div className="travel-Guides-desc">
              Find the perfect itinerary for your next escape!
            </div>
          </div>
          <OptionsInput
            placeholder="Find your dream itinerary..."
            showSelect={false}
            style={{ width: "100%" }}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            errorMessage={searchErrorInput}
          />
        </div>
      </div>

      {searchInput.length >= 2 ? (
        <SearchPageContent
          searchItenairyList={searchItenairyList}
          searchInput={searchInput}
          loading={loading}
        />
      ) : (
        <div>
          {/* <CardsWithScroll heading={"Trending Destinations"} cards={card} /> */}
          <CardsWithScroll
            heading={"Popular In Customers"}
            cards={popularTripsList}
            loadingImages={loadingImages}
          />
          <VacationList />
          <CardsWithScroll
            heading={"Weekend Getaways"}
            cards={weekendGatewayList}
            loadingImages={loadingImages}
          />
          <CardsWithScroll
            heading={"Long Trips"}
            cards={longTripsList}
            loadingImage={loadingImages}
          />

          <FeaturedTravellers
            bgColor={"gradient"}
            featuredTravellersList={featuredTravellersList}
          />
        </div>
      )}
    </>
  );
};

export default TravelGuides;
