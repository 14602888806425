import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import BackIcon from "../../Assets/BackIcon.svg";
import { getStoredImages } from "../../helper";
import { PassIcon } from "../atoms/PassIcon";
import Header from "../Header";
import PreviewModal from "./PreviewModal";

export const AllImagesPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const [state, setState] = useState([]);
  const { placeDetailsPhotos } = location.state || {};
  const [open, setOpen] = useState(false);

  // Create an array of image URLs

  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Mulish",
  };

  useEffect(() => {
    if (placeDetailsPhotos?.length > 0) {
      const images =
        placeDetailsPhotos?.map((photo) =>
          getStoredImages(photo.photo_reference)
        ) || [];
      setImages(images);
    }
  }, [placeDetailsPhotos]);

  useEffect(() => {
    if (images?.length) {
      let variable = chunkImages(images, 3);

      setState(variable);
    }
  }, [images]);

  const chunkImages = (imagesArray, chunkSize) => {
    const result = [];
    for (let i = 0; i < imagesArray.length; i += chunkSize) {
      result.push(imagesArray.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Header />
      <div className="all-images-preview-responhsive">
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
          className="paddingResponsiveDestination"
        >
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />
            <Typography style={typographyStyle}>Back</Typography>
          </div>

          {/* <SideClicks placeDetails={placeDetails} /> */}
        </div>
        {state?.map((item) => {
          return CardsPlacementWebView(item, setOpen);
        })}

        <div className="all-images-preview-main-mobile">
          {images?.map((src, index) => {
            if (!src?.config?.url) return null;
            return (
              <div key={index} style={{ width: "100%" }}>
                <img
                  key={index}
                  src={src?.config?.url}
                  alt={`Image ${index}`}
                  style={{
                    borderRadius: "16px",
                    maxHeight: "200px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="show-preview-modal-web">
        {open && (
          <PreviewModal open={open} handleClose={handleClose} images={images} />
        )}
      </div>
    </div>
  );
};

const CardsPlacementWebView = (item, setOpen) => {
  if (item?.length < 3) return null;
  return (
    <div className="all-images-preview-main">
      <img
        src={item[0]?.config?.url}
        alt=""
        style={{
          borderRadius: "16px",
          width: "100%",
          maxHeight: "400px",
          cursor: "pointer",
          objectFit: "cover",
        }}
        onClick={() => setOpen(true)}
      />
      <div
        style={{
          display: "flex",
          gap: "15px",
          width: "100%",
        }}
      >
        <img
          src={item[1]?.config?.url}
          alt=""
          style={{
            borderRadius: "16px",
            width: "100%",
            maxHeight: "200px",
            cursor: "pointer",
            objectFit: "cover",
          }}
          onClick={() => setOpen(true)}
        />

        <img
          src={item[2]?.config?.url}
          alt=""
          style={{
            borderRadius: "16px",
            width: "100%",
            maxHeight: "200px",
            cursor: "pointer",
            objectFit: "cover",
          }}
          onClick={() => setOpen(true)}
        />
      </div>
    </div>
  );
};
