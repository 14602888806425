import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowBack from "../../Assets/ArrowBack.svg";
import ArrowNext from "../../Assets/ArrowNext.svg";
import { sortByTypes, typesOfTrip } from "../../Constants/Constant";
import { sortingItenairy } from "../../redux/actions/authActions";
import { Card } from "../template/Card";
import { PlanYourNextTrip } from "../template/PlanYourNextTrip";
import CustomSelect from "../atoms/Select";

const CARDS_PER_PAGE = 18;

export const SearchPageContent = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const [searchInput, setSearchInput] = useState("");

  const totalPages = Math.ceil(
    props?.searchItenairyList?.length / CARDS_PER_PAGE
  );
  const startIndex = (currentPage - 1) * CARDS_PER_PAGE;

  const [sortBy, setSortBy] = useState("");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [selectedOption, setSelectedOption] = useState({
    name: "",
    displayName: "",
  });

  const getStoredImages = (reference) => {
    const images = localStorage.getItem(`images_${reference}`);
    return images ? JSON.parse(images) : [];
  };

  useEffect(() => {
    const payload = {
      // searchInput: searchInput,
      sortBy: sortBy.toLowerCase(),
      selectedOption: selectedOption.name,
    };
    dispatch(sortingItenairy(payload));
  }, [sortBy, selectedOption]);

  const handleChange = (e) => {
    setSortBy(e.target.value);
  };

  return (
    <>
      {props?.loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "200px",
          }}
        >
          <CircularProgress
            variant="indeterminate"
            sx={{
              color: "#20B08F",
            }}
            size={40}
            thickness={4}
          />
        </div>
      ) : (
        <>
          {" "}
          <div className="search-detail-page-conetent">
            <div className="search-page-conetent-in">
              <div>
                <p className="main-heading">
                  ‘{props?.searchInput}’ Itinerary{" "}
                </p>
                <p className="description">
                  {props?.searchItenairyList?.length} results found
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "20px",
                }}
              >
                <CustomSelect
                  labelId="sort-by-label"
                  id="sort-by-select"
                  value={sortBy}
                  handleChange={(e) => {
                    setSortBy(e);
                  }}
                  options={sortByTypes}
                  placeholder="Select Option"
                  adornmentText="Sort by"
                />
                <CustomSelect
                  labelId="trip-select-label"
                  id="trip-select"
                  value={selectedOption?.displayName}
                  handleChange={(e) => {

                    setSelectedOption(e);
                  }}
                  options={typesOfTrip}
                  placeholder="Select Trip"
                  // adornmentText="Trip"
                  sx={{ width: { xs: "100%", sm: "250px" } }} // Example of overriding styles
                  defaultKey={"displayName"}
                />
              </div>
            </div>

            {props?.searchItenairyList?.length ? (
              <>
                {" "}
                <div className="cards-list-detail-page">
                  {props?.searchItenairyList?.map((data, index) => (
                    <Card
                      key={index}
                      data={data}
                      style={{
                        width: "360px",
                        boxShadow: "0px 2px 18px 3px #00000005",
                      }}
                      height="auto"
                      image={getStoredImages(data?.reference)}
                    />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "32px",
                  }}
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <img src={ArrowBack} alt="Arrow back" />
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      style={{
                        margin: "0 5px",
                        padding: "0px 10px",
                        cursor: "pointer",
                        backgroundColor:
                          currentPage === index + 1 ? "#20B08F" : "white",
                        color: currentPage === index + 1 ? "#fff" : "#000",
                        border: "1px solid #F1F1F1",
                        borderRadius: "8px",
                      }}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <img src={ArrowNext} alt="Arrow Next" />
                  </button>
                </div>
              </>
            ) : (
              <Typography
                textAlign={"center"}
                color={"#20B08F"}
                fontSize={"20px"}
                fontWeight={"600"}
                fontFamily={"Mulish"}
              >
                No itinerary Found
              </Typography>
            )}
            <PlanYourNextTrip
              style={{
                flexDirection: "row-reverse",
                backgroundColor: "rgba(224, 239, 235, 0.3)",
                borderRadius: "10px",
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
