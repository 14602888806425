import { Button } from "@mui/material";
import MobileImage from "../Assets/MobileImage.svg";
import stars from "../Assets/stars.svg";
import yellowStar from "../Assets/yellowStar.svg";
import { useNavigate } from "react-router-dom";

const DownloadAppCard = () => {
  const navigate = useNavigate();
  return (
    <div className="bothSidesPadding">
      <div className="download-app-card">
        <div className="left">
          <div>
            <div className="text-container">
              Craft Your Dream Trip In Seconds with AI
            </div>
            <div
              style={{
                fontFamily: "Mulish",
                fontSize: "16px",
                color: "#999999",
                fontWeight: "500",
              }}
            >
              Answer a few questions and let AI plan your trip!
            </div>
          </div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#333333",
              textTransform: "none",
              padding: "13px 30px 13px 30px",
              borderRadius: "10px",
              width: "200px",
              fontWeight: "700",
              fontSize: "16px",
              fontFamily: "Mulish",
              "&:hover": {
                backgroundColor: "#faf9f6",
                borderColor: "#20B08F",
                borderWidth: "1px",
                "& .MuiTypography-root": {
                  color: "white", // Change text color on hover
                },
              },
            }}
            onClick={() => navigate("/get-help")}
          >
            Download App
          </Button>
        </div>
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${stars}),url(${yellowStar})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right,top left",
          }}
        >
          <div>
            <img src={MobileImage} alt="Escapekar : Mobile image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppCard;
