/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { loadMapKitScript } from "../../Utils/LoadMapKit";
import binocularIcon from "../../Assets/AttractionIcon.svg";
import bedIcon from "../../Assets/Hotel.png";
import utensilsIcon from "../../Assets/Restaurant.png";
import flagIcon from "../../Assets/flag.png";
import { mapToKenProd } from "../../redux/constants/apiConstants";
import { Tooltip, Typography } from "@mui/material";
import { PlacesType } from "../../Constants/Constant";
import StarIcon from "../../Assets/StarIcon.svg"
import DummyImage from "../../Assets/destinationDummyImg.jpg"

const AppleMap = ({ markers = [], setMarkers, onSelectMarker }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState(null);

  const calculateBoundingRegion = (markerArray) => {
    if (!markerArray.length) return null;

    let minLat = Infinity,
      maxLat = -Infinity,
      minLng = Infinity,
      maxLng = -Infinity;

    markerArray.forEach(({ latitude, longitude }) => {
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });

    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    const latSpan = (maxLat - minLat) * 1.5;
    const lngSpan = (maxLng - minLng) * 1.5;

    return {
      center: new mapkit.Coordinate(centerLat, centerLng),
      span: new mapkit.CoordinateSpan(latSpan, lngSpan),
    };
  };

  useEffect(() => {
    let map = null;

    loadMapKitScript(() => {
      if (window.mapkit) {
        mapkit.init({
          authorizationCallback: function (done) {
            done(mapToKenProd);
          },
        });

        map = new mapkit.Map(mapRef.current, {
          center: new mapkit.Coordinate(20.5937, 78.9629), // India coordinates
          span: new mapkit.CoordinateSpan(0.02, 0.02), // Adjusted initial zoom level
        });

        mapInstance.current = map; // Store the map instance for later use
      }
    });

    return () => {
      if (mapInstance.current) {
        mapInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!mapInstance.current || markers.length === 0) return;

    // Calculate bounding region
    const boundingRegion = calculateBoundingRegion(markers);

    if (boundingRegion) {
      // Adjust the map's region to fit all markers
      mapInstance.current.region = new mapkit.CoordinateRegion(
        boundingRegion.center,
        boundingRegion.span
      );
    }
  }, [markers]);

  useEffect(() => {
    if (!mapInstance.current || markers.length === 0) return;

    // Clear existing annotations
    mapInstance.current.annotations.forEach((annotation) => {
      mapInstance.current.removeAnnotation(annotation);
    });

    const getIconForType = (type) => {
      switch (type) {
        case "Attraction":
          return binocularIcon;
        case "Stay":
          return bedIcon;
        case "Eat":
          return utensilsIcon;
        case "Place":
          return flagIcon;
        default:
          return flagIcon;
      }
    };

    // Add new annotations
    markers.slice(0, 10).forEach((marker, index) => {
      const {
        latitude: propsLatitude,
        longitude: propsLongitude,
        title = "",
        subtitle = "",
        type = "",
      } = marker;

      const latitude = parseFloat(propsLatitude);
      const longitude = parseFloat(propsLongitude);
      const coordinate = new mapkit.Coordinate(latitude, longitude);
      const annotation = new mapkit.MarkerAnnotation(coordinate, {
        title,
        subtitle,
        glyphImage: {
          1: getIconForType(type),
          2: getIconForType(type),
          3: getIconForType(type),
        },
        color: "#20B08F",
      });

      annotation.addEventListener("select", () => {
        const newMarkers = markers.map((m, i) => ({
          ...m,
          selected: i === index,
        }));
        setMarkers(newMarkers);
        onSelectMarker(marker);
      });

      annotation.addEventListener("mouseover", () => {
        setHoveredMarkerIndex(index);
      });

      annotation.addEventListener("mouseout", () => {
        setHoveredMarkerIndex(null);
      });

      mapInstance.current.addAnnotation(annotation);
    });
  }, [markers, hoveredMarkerIndex, setMarkers, onSelectMarker]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '100%', borderRadius: '20px' }}
      />
      <div
        className="Apple-cards-container"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '180px', // Adjust the height of the cards container
          overflowX: 'auto',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          zIndex: 10, // Ensure it appears above the map
          scrollbarWidth: 'none', /* Firefox */
          msOverflowStyle: 'none', /* Internet Explorer and Edge */
        }}
      >
        {/* Example card content */}
        {markers.map((marker, index) => (
          <div
            key={index}
            className="Apple-card"
            style={{
              minWidth: '360px',
              height: '150px', // Adjust the card height
              marginRight: '10px',
              padding: '10px',
              borderRadius: '20px',
              boxShadow: "0px 3px 28px 3px #0000000A",
              backgroundColor: "#FFFFFF",
              display: "flex",
              gap: "20px"
            }}
          >
            <img src={marker?.image?.config?.url || DummyImage} alt="" style={{ width: "130px", borderRadius: "20px" }} />
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px"
            }}>
              <Tooltip title={marker?.title}>
                <Typography
                  color="#000000"
                  fontSize="16px"
                  fontFamily="Mulish"
                  fontWeight="bold"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: "180px",
                    cursor: "pointer"
                  }}
                >
                  {marker.title}
                </Typography>
              </Tooltip>
              <Typography
                fontSize={"12px"}
                color={"#999999"}
                fontWeight={"500"}
                fontFamily={"Mulish"}
              >
                {marker?.type === "PLACE" ? marker.placeType ?? PlacesType[marker.type] : PlacesType[marker.type]}
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                {marker?.rating && (
                  <div style={{ display: "flex", gap: "3px" }}>
                    <img
                      src={StarIcon}
                      alt="place img"
                      width={"10px"}
                      height={"10px"}
                    />
                    <Typography
                      fontSize={"12px"}
                      color={"#CCCCCC"}
                      fontWeight={"700"}
                      fontFamily={"Mulish"}
                    >
                      {`${marker?.rating} (${marker?.totalRatings})`}
                    </Typography>
                  </div>
                )}

              </div>
              <Typography
                fontSize={"12px"}
                color={"#CCCCCC"}
                fontWeight={"700"}
                fontFamily={"Mulish"}
              >
                {marker?.costRange ? (
                  `${marker?.costRange} /person`
                ) : (
                  <></>
                )}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppleMap;
