import React, { useEffect } from "react";

import Slide from "@mui/material/Slide";

import { useDispatch, useSelector } from "react-redux";
import Apple from "../Assets/AppleIcon.png";
import HotAirBalloon from "../Assets/HotAirBalloon.svg";
import Travel2 from "../Assets/Montreal.png";
import Android from "../Assets/PlayStore.png";
import Travel1 from "../Assets/Salar.png";
import SmallQR from "../Assets/SmallQR.svg";
import Travel4 from "../Assets/Sunken.svg";
import TravelGraphic from "../Assets/TravelGraphic";
import Travel3 from "../Assets/WarmUpWarm.svg";
import AppDetails from "../Components/AppDetails";
import DownloadAppCard from "../Components/DownloadAppCard";
import FeaturedTravellers from "../Components/FeaturedTravellers";
import Header from "../Components/Header";
import { PopularAmongTravellersSection } from "../Components/PopularAmongTravllersSection/PopularAmongTravllersSection";
import QRModal from "../Components/QRMOdal";
import VacationList from "../Components/VacationList";
import CardsWithScroll from "../Components/atoms/CardsWithScroll";
import {
  getPublishedTrip,
  getTrendingDestinations,
  getfeaturedTravllersList,
} from "../redux/actions/authActions";
import { clearLocalStorage } from "../helper";
import { useLocation } from "react-router-dom";
import { logPageView } from "../analytics";
import { Helmet } from "react-helmet";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    tripData,
    featuredTravellersList,
    trendingDestinationsList = [],
    loadingImages,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    clearLocalStorage();
    dispatch(getPublishedTrip());
    dispatch(getfeaturedTravllersList());
    dispatch(getTrendingDestinations());

  }, []);

  useEffect(() => {
    let obj = {
      name: "Home",
      path: location.path,

    }
    logPageView(obj);
  }, [location])

  return (
    <>
      <Helmet> <title>Escapekar: Trip Planner</title>
        <meta
          name="title"
          content="Escapekar: Trip Planner"
        />
        <meta
          name="description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />

        <meta name="url" content="https://escapekar.com/" />
        
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />


        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />

        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
      </Helmet>
      <div className="Home flex flex-col min-h-screen h-full w-full justify-between mt-10">
        <div
          style={{
            background:
              "linear-gradient(180deg, #E2FFF8 14.25%, rgba(255, 255, 255, 0) 100%)",
          }}
        >
          <Header boxShadow={"none"} />

          <div className="MainTag bothSidesPadding flex flex-col lg:flex-row items-center pb-[30px] lg:pb-[67px] mt-10">
            <div className="HomeText flex flex-col items-center lg:items-start w-[100%] lg:w-[50%] mt-12 lg:mt-[0]">
              <h1 className="text-[26px] leading-[36px] md:leading-[70px] md:text-[56px] font-bold max-w-[581px] lg:max-w-none mb-5 font-Mulish text-center md:text-left">
                Let’s explore, create and manage trip{" "}
                <span className="lg:block text-red-500">for free</span>
              </h1>
              <p className=" text-base md:text-2xl lg:text-base font-normal max-w-[533px] mb-10 font-Mulish text-[#00000080] text-center md:text-left">
                For many people organising trip is a headache, we made it easy
                for you plus we won’t charge you for that
              </p>
              <div className="flex flex-row items-center gap-[10px]">
                <a
                  alt="Play Store"
                  href="https://play.google.com/store/apps/details?id=com.escapekar.escapekar"
                  target="_blank"
                >
                  <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                    <img alt="Google play" src={Android} className="my-2" />
                    <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                      <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                        GET IT ON
                      </p>
                      <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                        Google Play
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  alt="Play Store"
                  href="https://apps.apple.com/in/app/escapekar-ai-trip-planner/id6446503558"
                  target="_blank"
                >
                  <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
                    <img
                      alt="App Store"
                      src={Apple}
                      className="my-1"
                      width="20%"
                    />
                    <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
                      <p className=" text-[9px] font-bold font-Mulish text-[#ffffff]">
                        DOWNLOAD ON THE
                      </p>
                      <p className=" text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
                        App Store
                      </p>
                    </div>
                  </div>
                </a>
                <div className="hidden lg:flex">
                  <img
                    src={SmallQR}
                    alt="QR"
                    onClick={handleOpen}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            <QRModal open={open} handleClose={handleClose} />

            <div className="flex flex-col items-center lg:items-end lg:justify-center w-[100%] lg:w-[50%] mt-14 lg:mt-4">
              <TravelGraphic />
            </div>
          </div>
        </div>

        {/* popular trips */}
        <CardsWithScroll
          heading={"Popular Trips"}
          description="Dive into the itineraries our customers love most."
          cards={tripData?.trips}
          showbtn={true}
          loadingImages={loadingImages}
        // handleViewallClick={() => location("/travel-guides")}
        />
        <DownloadAppCard />

        <PopularAmongTravellersSection
          trendingDestinationsList={trendingDestinationsList}
          loadingImages={loadingImages}
        />

        <VacationList />

        <FeaturedTravellers
          showBtn={true}
          featuredTravellersList={featuredTravellersList}
        />
      </div>
      {/* <div className="Fold2 relative flex flex-col min-h-screen md:min-h-fit lg:min-h-screen h-full w-full px-5 lg:px-20 items-center pb-16 md:pb-20 lg:pb-[140px]">
        <img
          src={Compass2}
          alt="Compass"
          className="hidden lg:flex absolute top-[0%] -right-[6%]"
        />
        <h2 className="text-[24px] lg:text-[46px] font-bold font-Mulish mt-16 lg:mt-20">
          What We Got For You
        </h2>
        <p className="text-base md:text-2xl lg:text-base text-center lg:text-left font-normal font-Mulish mt-2 lg:mt-0 lg:mb-20 text-[#00000080]">
          Enjoy different experiences in every place you visit
        </p>
        <div className="Cards flex flex-wrap flex-col justify-center md:flex-row gap-5 md:gap-3 lg:gap-5 pt-12 lg:pt-0">
          <div className="Card flex flex-row md:flex-col h-auto w-[350px] md:w-[320px] lg:h-[540px] lg:w-[360px] items-center justify-end rounded-[10px] md:rounded-[30px] bg-[#ffffff]">
            <div className="flex flex-col justify-center items-center h-[160px] md:h-[320px] lg:h-[360px] w-[50%] md:w-[320px] lg:w-[360px] bg-[#FEF4E2] rounded-tl-[10px] rounded-bl-[10px] md:rounded-t-[30px] md:rounded-bl-[0px] p-3 lg:p-0">
              <img src={AllAtOnePlace} alt="All At One Place" />
            </div>
            <div className="flex flex-col w-[50%] md:w-[320px] lg:w-auto min-h-[160px] lg:min-h-auto justify-center items-center px-[12px] md:px-[32px] m-auto">
              <p className=" text-base md:text-xl lg:text-2xl font-bold font-Mulish text-center mt-0 md:mt-[18px] mb-[10px]">
                All At One Place
              </p>
              <p className="text-xs md:text-base font-medium leading-[20px] font-Mulish text-[#323232] text-center pb-0 md:pb-[50px]">
                Get your flight, hotel, reservations etc all at one place
              </p>
            </div>
          </div>
          <div className="Card flex flex-row md:flex-col h-auto w-[350px] md:w-[320px] lg:h-[540px] lg:w-[360px] items-center justify-end rounded-[10px] md:rounded-[30px] bg-[#ffffff]">
            <div className="flex flex-col justify-center items-center h-[160px] md:h-[320px] lg:h-[360px] w-[50%] md:w-[320px] lg:w-[360px] bg-[#F3E9FF] rounded-tl-[10px] rounded-bl-[10px] md:rounded-t-[30px] md:rounded-bl-[0px] p-3 lg:p-0">
              <img src={BestRecommendations} alt="Best Recommendations" />
            </div>
            <div className="flex flex-col w-[50%] md:w-[320px] lg:w-auto min-h-[160px] lg:min-h-auto justify-center items-center px-[12px] md:px-[32px] m-auto">
              <p className=" text-base md:text-xl lg:text-2xl font-bold font-Mulish text-center mt-0 md:mt-[18px] mb-[10px]">
                Best Recommendations
              </p>
              <p className="text-xs md:text-base font-medium leading-[20px] font-Mulish text-[#323232] text-center pb-0 md:pb-[50px]">
                Personalised suggestions for your trip, find hidden places &
                many more
              </p>
            </div>
          </div>
          <div className="Card flex flex-row md:flex-col h-auto w-[350px] md:w-[320px] lg:h-[540px] lg:w-[360px] items-center justify-end rounded-[10px] md:rounded-[30px] bg-[#ffffff] z-10">
            <div className="flex flex-col justify-center items-center h-[160px] md:h-[320px] lg:h-[360px] w-[50%] md:w-[320px] lg:w-[360px] bg-[#E9F7F4] rounded-tl-[10px] rounded-bl-[10px] md:rounded-t-[30px] md:rounded-bl-[0px] p-3 lg:p-0">
              <img src={OptimiseRoute} alt="Optimise Route" />
            </div>
            <div className="flex flex-col w-[50%] md:w-[320px] lg:w-auto min-h-[160px] lg:min-h-auto justify-center items-center px-[12px] md:px-[32px] m-auto">
              <p className=" text-base md:text-xl lg:text-2xl font-bold font-Mulish text-center mt-0 md:mt-[18px] mb-[10px]">
                Optimise Your Route
              </p>
              <p className="text-xs md:text-base font-medium leading-[20px] font-Mulish text-[#323232] text-center pb-0 md:pb-[50px]">
                Find the shortest route for your trip while saving your time &
                money.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="Fold3 relative flex flex-col h-full lg:min-h-[800px] w-full  items-center bg-[#105847] pb-16 md:pb-20 lg:pb-5 ">
        <img
          src={HotAirBalloon}
          alt="Hot Air Balloon"
          className="hidden lg:flex absolute -top-[12%] left-[2%]"
        />
        <h2 className=" text-[24px] lg:text-[46px] font-bold font-Mulish mt-16 lg:mt-20 text-[#ffffff]">
          Look for hidden spots
        </h2>
        <p className="text-base md:text-2xl lg:text-base text-center lg:text-left font-normal font-Mulish mt-2 lg:mt-0 mb-28 lg:mb-28 text-[#ffffff80]">
          All the hidden spots will be on your finger tips
        </p>
        <div className="Cards flex flex-row gap-10 md:gap-16 lg:gap-28 flex-wrap lg:flex-initial justify-center">
          <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] -mt-6 ml-5 lg:ml-0 lg:mt-24">
            <img alt="Scene" src={Travel1} className=" mb-5" />
            <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
              Salar de Uyuni
            </p>
            <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
              Bolivia
            </p>
          </div>
          <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] mt-24 lg:mt-0 mr-5 lg:mr-0">
            <img alt="Scene" src={Travel2} className=" mb-5" />
            <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
              Montreal Garden
            </p>
            <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
              Canada
            </p>
          </div>
          <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] -mt-6 ml-5 lg:ml-0 lg:mt-24">
            <img alt="Scene" src={Travel3} className=" mb-5" />
            <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
              Rainbow Mountains
            </p>
            <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
              Gansu, China
            </p>
          </div>
          <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] mt-24 lg:mt-0 mr-5 lg:mr-0">
            <img alt="Scene" src={Travel4} className=" mb-5" />
            <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
              The Great Blue Hole
            </p>
            <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
              Belize, America
            </p>
          </div>
        </div>
      </div>
      <AppDetails />

      {/* <div className="Fold4 relative flex flex-col h-full w-full items-center pb-0 md:pb-20 lg:pb-0 bg-[#fff]">
        <img
          src={Compass}
          alt="Compass"
          className="hidden lg:flex absolute w-[113px] h-[123.24px] -top-[9%] left-[45%]"
        />
        <h2 className=" text-[24px] lg:text-[46px] font-bold font-Mulish mt-16 lg:mt-20 px-5 lg:px-20">
          Across the World
        </h2>
        <p className="text-base md:text-2xl lg:text-base text-center lg:text-left font-normal font-Mulish mt-2 lg:mt-0 mb-9 lg:mb-20 text-[#00000080] px-5 lg:px-20">
          Enjoy different experiences in every place you visit
        </p>
        <div
          alt="Map"
          style={{
            backgroundImage: `url(${Map})`,
          }}
          className="flex md:hidden w-full h-[400px] bg-center bg-cover"
        />
        <img alt="Map" src={Map} className="w-[80%] hidden md:flex" />
      </div> */}
      {/* <div className="Fold5 relative flex flex-col h-full w-full px-0 md:px-20 items-center pb-[0] md:pb-[100px] bg-[#fff]">
        <img
          src={DirectionBoard}
          alt="Direction Board"
          className="hidden lg:flex absolute bottom-[23%] left-[30%]"
        />
        <div className="flex flex-col lg:flex-row h-full w-full md:w-[600px] lg:h-[344px] lg:w-[1000px] px-[20px] lg:px-[110px] pt-[60px] pb-[80px] lg:pt-[40px] lg:pb-[40px] bg-[#F9E5C8] rounded-none md:rounded-[68px]">
          <img
            alt="Subscribe"
            src={Subscribe}
            className="w-[100%] md-[50%] lg:w-[50%] ml-5 lg:ml-0 my-5 lg:my-0 z-[10]"
          />
          <div className="flex flex-col items-center justify-center">
            <p className=" text-base lg:text-xl font-semibold font-Mulish text-center mt-8 md:mt-0 mb-8">
              Subscribe to get travel updates and other interesting offers about
              Escapekar.
            </p>
            <form className="flex flex-row">
              <input
                type="email"
                className="flex text-base text-left font-Mulish px-[16px] py-[12px] rounded-tl-[10px] rounded-bl-[10px] rounded-tr-[0px] rounded-br-[0px] w-[230px] lg:w-[328px] outline-none placeholder:text-[#ccc] placeholder:text-sm"
                placeholder="Enter your email address"
              />
              <button className="flex text-base font-semibold justify-center font-Mulish px-[24px] py-[12px] rounded-tr-[10px] rounded-br-[10px] rounded-tl-[0px] rounded-bl-[0px] text-[#ffffff] bg-[#20B08F] hover:bg-[#1b967a]">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
