import { Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/BackIcon.svg";
import { destinationDetailTypes } from "../../Constants/Constant";
import { getPlaceDetails } from "../../redux/actions/authActions";
import DownloadAppCard from "../DownloadAppCard";
import Header from "../Header";
import { PassIcon } from "../atoms/PassIcon";
import AboutCard from "./AboutCard";
import CardsWithScroll from "./CardsWithScroll";
import { HeaderDetailPage } from "./HeaderDetailPage";
import { Ratings } from "./Ratings";
import { RatingCard } from "./Reviews";
import { ViewAllImages } from "./ViewAllImages";
import "./destination.css";
import { logPageView } from "../../analytics";
import { Helmet } from "react-helmet";

export const DestinationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { placeDetails, loadingDestination } = useSelector(
    (state) => state.auth
  );
  const { card } = location.state || {};
  const typeOfTags = placeDetails?.tags;
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Mulish",
  };



  useEffect(() => {
    let obj = {
      name: "Destination Detail",
      path: location.path,

    }
    logPageView(obj);
    if (location.pathname) {

      const payload = {
        placeId: location.pathname.split("/")[3],
      };
      dispatch(getPlaceDetails(payload));
    }
  }, [location])

  return (
    <>
      <Helmet> <title>Escapekar: Best hidden gems
      </title>
        <meta
          name="title"
          content="Escapekar: Best hidden gems"
        />
        <meta
          name="description"
          content="Discover hidden gems like Diveagar Beach, Kollukumalai, and Mandvi with Escapekar. Our AI-powered trip planner helps you explore secret places and best hidden spots for your next adventure. Plan your trip effortlessly with personalized recommendations and explore destinations tailored to your interests."
        />
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />

        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TouristAttraction",
            name: "Escapekar : Trip Planner",
            alternateName: "Escapekar",
            url: "https://escapekar.com/",

            contactPoint: {
              "@type": "ContactPoint",
              // telephone: "+91 9599819940",
              // contactType: "customer service",
              // contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],
          })}
        </script>

      </Helmet>
      <div>
        <div className="hidden lg:flex">
          <Header />
        </div>{" "}
        <div className="destination-detail-main ">
          <div className="destination-detail-mobile-header">
            <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

            <Typography
              style={{
                ...typographyStyle,
                fontSize: "16px",
                color: "#000000",
                marginTop: "10px",
              }}
            >
              Go Back
            </Typography>
          </div>
          <HeaderDetailPage
            placeDetails={placeDetails}
            loadingDestination={loadingDestination}
            placeId={card?.place_id}

          />
          {loadingDestination ? (
            <Skeleton variant="rectangular" width={"100%"} height={200} />
          ) : (
            <ViewAllImages
              placeDetailsPhotos={placeDetails?.photos}
              placeDetails={placeDetails}
            />
          )}
          {/* Overview */}

          {placeDetails?.editorial_summary?.overview ? (
            <>
              {loadingDestination ? (
                <Skeleton variant="rectangular" width={210} height={118} />
              ) : (
                <AboutCard
                  heading={`About ${placeDetails?.administrativeAreaLevel1}`}
                  about={placeDetails?.editorial_summary?.overview}
                  loadingDestination={loadingDestination}
                />
              )}
            </>
          ) : (
            <></>
          )}

          {/* popular trips */}
          <CardsWithScroll
            heading={
              typeOfTags === destinationDetailTypes[typeOfTags]
                ? "Popular Attractions"
                : "Nearby Attractions"
            }
            cards={placeDetails?.attractions?.results}
            showbtn={true}
            loadingDestination={loadingDestination}
          />

          <DownloadAppCard />

          {/* popular trips */}
          {placeDetails?.hiddenPlaces && (
            <CardsWithScroll
              heading={"Hidden Gems"}
              cards={placeDetails?.hiddenPlaces?.results}
              showbtn={true}
            />
          )}

          <div className="review-ratings ">
            <div>
              {placeDetails?.googleReviews?.length > 0 && (
                <Ratings placeDetails={placeDetails} />
              )}
            </div>
            <div className="rating-cards-list">
              {placeDetails?.googleReviews?.length > 0
                ? placeDetails?.googleReviews?.map((authour) => {
                  return <RatingCard authour={authour} />;
                })
                : null}
            </div>
          </div>
        </div>
      </div></>
  );
};
