import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../components.css";
import Button1 from "./Button1";
import { Card } from "../template/Card";
import { useDispatch } from "react-redux";
import { getItineraryDetails } from "../../redux/actions/authActions";
import { ArrowIcon } from "./arrowIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CardsWithScroll = (props) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const navigate = useNavigate();

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const handleScroll = () => {
    setIsScrolled(containerRef.current.scrollLeft > 0);
    updateScrollButtons();
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      updateScrollButtons(); // Check scroll buttons on mount

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    updateScrollButtons(); // Update scroll buttons when cards are loaded
  }, [props.cards]);

  const getStoredImages = (reference) => {
    const images = localStorage.getItem(`images_${reference}`);
    return images ? JSON.parse(images) : [];
  };

  const handleCardClick = (card) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
    dispatch(getItineraryDetails(card.id));
  };

  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        cursor: "pointer",
        margin: "50px 0px",
      }}
    >
      <div className="main-popular-tags">
        <div>
          <div className="leftSidePadding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p className="main-heading">{props?.heading}</p>
                <p className="description">{props?.description}</p>
              </div>
              <div className="scroll-Icon">
                <ArrowIcon
                  icon={<ArrowBackIcon />}
                  onClick={() => scroll(-100)}
                  disabled={!canScrollLeft}
                />
                <ArrowIcon
                  icon={<ArrowForwardIcon />}
                  onClick={() => scroll(100)}
                  disabled={!canScrollRight}
                />
              </div>
            </div>
          </div>
          <div
            className={`card-list-container ${
              isScrolled ? "remove-padding" : ""
            }`}
            style={{ marginTop: "50px" }}
          >
            <div className="card-list-travellers" ref={containerRef}>
              {props?.cards?.map((data, index) => (
                <Card
                  key={data.id}
                  image={getStoredImages(data?.reference)}
                  data={data}
                  index={index}
                  onClick={() => handleCardClick(data)}
                  loadingImages={props?.loadingImages}
                />
              ))}
            </div>
          </div>
        </div>

        {props?.showbtn === true && (
          <div style={{ margin: "auto" }}>
            <Button1
              variant="outlined"
              title="View all"
              border={"1px solid #20B08F"}
              fontSize={"15px"}
              onClick={() => navigate("/travel-guides")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsWithScroll;
