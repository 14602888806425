import React from "react";
import LocationUI from "./LocationUi";
import { SideClicks } from "./SideClicks";
import { Skeleton } from "@mui/material";
import "./destination.css";
export const HeaderDetailPage = ({ placeDetails, loadingDestination, placeId }) => {
  function formatLocation(placeDetails) {
    const locality = placeDetails?.locality ?? "";
    const country = placeDetails?.country ?? "";

    if (locality && country) {
      return `${locality}, ${country}`;
    } else if (locality) {
      return locality;
    } else if (country) {
      return country;
    } else {
      return ""; // Return an empty string if both are missing
    }
  }

  return (
    <div className="paddingResponsiveDestination">
      {/* headings and share icons */}
      <div className="md:flex md:flex-row  md:justify-between md:items-center mb-5 w-[100%]">
        {loadingDestination ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={70}
            className="rounded-lg"
          />
        ) : (
          <div className="flex flex-col space-y-2">
            <h1 className="text-2xl font-bold text-gray-800">
              {placeDetails?.name}
            </h1>
            <LocationUI
              location={formatLocation(placeDetails)}
            />

          </div>
        )}
        {loadingDestination ? (
          <Skeleton
            variant="rectangular"
            width={100}
            height={100}
            className="rounded-lg mt-4 md:mt-0"
          />
        ) : (
          <div className="mt-8 md:mt-0">
            <SideClicks placeDetails={placeDetails} placeId={placeId} />
          </div>
        )}
      </div>
    </div>
  );
};
