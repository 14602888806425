import { Stack, Typography } from "@mui/material";
import "../../Components/components.css";
import LocationIcon from "../../Assets/LocationIconGrey.svg";

const LocationUI = (props) => {
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#999999",
    fontFamily: "Mulish",
  };
  return (
    <div style={{ display: "flex", gap: "7px" }}>
      <img src={LocationIcon} alt="location icon" />
      <Typography
        style={{
          ...typographyStyle,
          fontSize: "12px",
          color: "#999999",
        }}
      >
        {props?.location}
      </Typography>
    </div>
  );
};

export default LocationUI;
