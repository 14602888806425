import { Stack } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DestinationImg from "../../Assets/destinationDummyImg.jpg";
import { getRandomIndex, getStoredImages } from "../../helper";
import { ArrowIcon } from "../atoms/arrowIcon";
import "./PopularAmongSection.css";

export const PopularAmongTravellersSection = ({ trendingDestinationsList }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardArr, setCardArr] = useState([]);
  const navigate = useNavigate();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleNext = () => {
    if (currentIndex + 6 < trendingDestinationsList.length) {
      setCurrentIndex(currentIndex + 6);
    }
    if (currentIndex + 13 > trendingDestinationsList.length) {
      setCanScrollRight(false);
      setCanScrollLeft(true);
    }
  };

  const handlePrev = () => {
    if (currentIndex - 6 >= 0) {
      setCurrentIndex(currentIndex - 6);
    }
    if (currentIndex - 13 <= 0) {
      setCanScrollRight(true);
      setCanScrollLeft(false);
    }
  };

  const handleClick = (card) => {
    navigate(`/destination-detail/${card.structured_formatting.main_text}/${card?.place_id}`, {
      state: { card },
    });
  };

  useEffect(() => {
    if (trendingDestinationsList.length >= 6) {
      let mod = trendingDestinationsList?.length % 6;
      let quotient = Math.floor(trendingDestinationsList?.length / 6);
      let spliceArr = trendingDestinationsList?.slice(0, 6 * quotient);
      if (mod) {
        setCanScrollRight(true);
        let remainingCards = 6 - mod;

        let randomIndex = getRandomIndex(spliceArr, remainingCards);
        let listArr = trendingDestinationsList;
        for (let i = 0; i < randomIndex?.length; i++) {
          listArr.push(trendingDestinationsList[randomIndex[i]]);
        }
        setCardArr(listArr);
      }
    }
  }, [trendingDestinationsList]);

  return (
    <div className="bothSidesPadding">
      <div className="popular-among-travellers-wrapper">
        <div className="popular-among-travellers-wrapper-in">
          <div className="popular-among-travellers-main-heading">
            Popular Among Travellers
          </div>
          <div className="popular-among-travellers-description">
            These are the destinations everyone’s got on their radar, don’t miss
            out!
          </div>
        </div>
        {/* cards */}
        <div
          style={{ width: "100%", overflow: "hidden", position: "relative" }}
        >
          <div className="background-text">DESTINATIONS</div>
          <div
            ref={containerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollBehavior: "smooth",
              marginTop: "70px",
            }}
          >
            <CardsplacementWeb
              cards={cardArr?.slice(currentIndex, currentIndex + 6)}
              handleClick={handleClick}
            />
          </div>

          <div
            ref={containerRef}
            className="card-list"
            style={{
              display: "flex",
              overflowX: "auto",
              scrollBehavior: "smooth",
            }}
          >
            <div className="cards-placement-mobile ">
              {trendingDestinationsList?.map((item, index) => (
                <Card
                  key={index}
                  width="170px"
                  height="250px"
                  item={item}
                  image={getStoredImages(item?.imageReference)}
                  onClick={() => handleClick(item)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="scroll-Icon" style={{ marginTop: "10px" }}>
          <ArrowIcon
            icon={<ArrowBackIcon />}
            onClick={() => handlePrev()}
            disabled={!canScrollLeft}
          />
          <ArrowIcon
            icon={<ArrowForwardIcon />}
            onClick={() => handleNext()}
            disabled={!canScrollRight}
          />
        </div>
      </div>
    </div>
  );
};

const Card = (props) => {
  return (
    <div className={`popular-among-travellers-card `} onClick={props?.onClick}>
      <div
        className="background-img"
        style={{
          backgroundImage: `url(${props?.image?.config?.url
              ? props?.image?.config?.url
              : DestinationImg
            })`,
          borderRadius: "20px",
          position: "relative",
          height: props?.isCenter === "yes" ? "100%" : props?.height,
          width: props?.width,
          objectFit: "cover",
        }}
      >
        <div className="gradient-overlay"></div>
        <div className="card-content">
          <div className="card-heading">
            {props?.item?.structured_formatting?.main_text}
          </div>
          {/* <div className="popular-among-travvlers-card-description">
            56 Itineraries
          </div> */}
        </div>
      </div>
    </div>
  );
};

const CardsplacementWeb = ({ cards, handleClick }) => {
  return (
    <div className="cards-placement-web">
      <Stack gap="20px" width="100%">
        <>
          <Card
            item={cards[0]}
            image={getStoredImages(cards[0]?.imageReference)}
            onClick={() => handleClick(cards[0])}
          />
          <div style={{ display: "flex", gap: "20px" }}>
            <Card
              item={cards[1]}
              image={getStoredImages(cards[1]?.imageReference)}
              onClick={() => handleClick(cards[1])}
            />
            <Card
              item={cards[2]}
              image={getStoredImages(cards[2]?.imageReference)}
              onClick={() => handleClick(cards[2])}
            />
          </div>
        </>
      </Stack>

      <Card
        isCenter={"yes"}
        item={cards[3]}
        image={getStoredImages(cards[3]?.imageReference)}
        onClick={() => handleClick(cards[3])}
      />

      <Stack gap="20px" width="100%">
        <Card
          item={cards[4]}
          image={getStoredImages(cards[4]?.imageReference)}
          onClick={() => handleClick(cards[4])}
        />
        <Card
          item={cards[5]}
          image={getStoredImages(cards[5]?.imageReference)}
          onClick={() => handleClick(cards[5])}
        />
      </Stack>
    </div>
  );
};
