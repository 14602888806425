import { Box } from "@mui/material";
import React from "react";
import Header from "../Components/Header";
import errorPagetext from "../Assets/404Text.svg";
import errorPage from "../Assets/ErrorImg.svg";
import Button1 from "./atoms/Button1";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
    const navigate = useNavigate()
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "100px" }}>
            <Header />
            <Box sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px"
            }}>
                <div
                    className="error-background-img"
                    style={{
                        backgroundImage: `url(${errorPagetext})`,

                        height: "200px",
                        width: "450px",
                        marginTop: "150px",
                        padding: "20px",
                        position: "relative", // Make the container a positioned element
                    }}
                >
                    <img
                        src={errorPage}
                        alt="Error Page"
                        style={{
                            height: "200px",
                            width: "198px",
                            position: "absolute", // Position the image absolutely within the container
                            top: "62%", // Move it to the middle vertically
                            left: "50%", // Move it to the middle horizontally
                            transform: "translate(-50%, -50%)", // Adjust for the image's dimensions
                        }}
                    />
                </div>
                <div className="error-page-oops-text">
                    Oops!
                </div>
                <div className="error-page-text">
                    Sorry, the link you followed may be broken, or the page may have been removed.
                </div>
                <Button1
                    variant="contained"
                    title="Go to Explore page"
                    onClick={() => navigate("/")}
                />
            </Box>
        </div >
    );
}
