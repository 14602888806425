import React from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const DynamicTabs = ({ tabs, value, onChange }) => {
  return (
    <TabContext value={value}>
      <Box
        sx={{
          backgroundColor: "#EAEAEA",
          borderRadius: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          minHeight: "40px",
        }}
      >
        <TabList
          onChange={onChange}
          aria-label="tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            borderRadius: "10px",
            minHeight: "30px",
            width: "100%",
            margin: "5px",
            height: "35px",
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{
                backgroundColor: value === tab.value ? "white" : "#EAEAEA",
                "&:hover": { backgroundColor: "white" },
                textTransform: "none",
                color: value === tab.value ? "#000000" : "#999999",
                fontWeight: value === tab.value ? 500 : "normal",
                width: "50%",
                height: "35px",
                minHeight: "30px",

                "&.Mui-selected": {
                  color: "unset",
                  height: "35px",
                  borderRadius: "10px",
                  minHeight: "30px",
                },
              }}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map((tab) => (
        <TabPanel
          key={tab.value}
          value={tab.value}
          sx={{ padding: "22px 0px", width: "100%" }}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default DynamicTabs;
