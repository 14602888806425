import { Stack, Typography } from "@mui/material";
import "../../Components/components.css";

const FollowersUI = (props) => {
  const typographyStyle = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Mulish",
  };
  return (
    <div className="travller-detail-follwers-box">
      <Stack gap="2px" alignItems={"center"}>
        <Typography
          style={{
            ...typographyStyle,
            fontSize: "20px",
            color: "#333333",
          }}
        >
          {props?.trips}
        </Typography>
        <Typography
          style={{
            ...typographyStyle,
            fontSize: "14px",
            color: "#CCCCCC",
          }}
        >
          Trips
        </Typography>
      </Stack>

      {/* Separator */}
      <div
        style={{
          border: "1px solid #EAEAEA",
          backgroundColor: "#CCCCCC",
          height: "100%",
        }}
      ></div>

      <Stack gap="2px" alignItems={"center"}>
        <Typography
          style={{
            ...typographyStyle,
            fontSize: "20px",
            color: "#333333",
          }}
        >
          {props?.followers ?? 0}
        </Typography>
        <Typography
          style={{
            ...typographyStyle,
            fontSize: "14px",
            color: "#CCCCCC",
          }}
        >
          Followers
        </Typography>
      </Stack>
    </div>
  );
};

export default FollowersUI;
