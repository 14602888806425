import * as React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

export default function Button1(props) {
  return (
    <Button
      variant={props.variant ? props.variant : "contained"}
      onClick={props?.onClick}
      sx={{
        backgroundColor: props?.variant === "contained" ? "#20B08F" : "",
        textTransform: "none",
        borderRadius: "10px",
        minHeight: "40px",
        width: props?.width ? props?.width : "150px",
        minWidth: "107px",
        boxShadow: "unset",
        border:
          props?.variant === "outlined"
            ? "1px solid #20B08F"
            : props?.border
              ? props.border
              : "",
        maxWidth: "200px",
        "&:hover": {
          backgroundColor: "#20B08F",
          borderColor: "#20B08F",
          borderWidth: "1px",
          "& .MuiTypography-root": {
            color: "white", // Change text color on hover
          },
        },
      }}
      startIcon={props.startIcon && <img src={props.startIcon} alt="icon" />}
      {...props?.sx}
    >
      <Typography
        fontFamily="Mulish"
        fontSize={props?.fontSize ? props.fontSize : "12px"}
        fontWeight={"700"}
        color={props?.variant === "contained" ? "#fff" : "#20B08F"}
      >
        {props?.title}
      </Typography>
    </Button>
  );
}
