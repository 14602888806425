
import React, { useEffect } from "react";
import QRModal from "../Components/QRMOdal";
import Header from "./Header";
import { PlanYourNextTrip } from "./template/PlanYourNextTrip";
import { logPageView } from "../analytics";
import { useLocation } from "react-router-dom";

const GetApp = () => {
  const location = useLocation()
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  useEffect(() => {

    let obj = {
      name: "Get app",
      path: location.path,

    }
    logPageView(obj);
  }, [location])

  return (
    <div className="Home flex flex-col min-h-screen h-full w-full justify-between bg-gradient-to-b from-[#E2FFF8] to-[#f1f1f1] ">
      <Header />
      <div className="mt-[100px] lg:mt-0">
        <PlanYourNextTrip />
      </div>
      <QRModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default GetApp;
